import { memo, useState, useCallback, useEffect, useRef } from 'react';
//import { SectionForm, Select } from 'hoi-poi-ui';

import { errorToast } from 'utils/toast';
import { getLiteral } from 'utils/getLiteral';
import dot from 'utils/dot';
import { isEmptyObject } from 'utils/objects';

import { getAutomationVariables } from 'services/AutomationsService';

import AutomationBuilderSidePanel from './AutomationBuilderSidePanel';

const AutomationBuilderConditionsSidePanel = memo(
    ({ data, onChange, onClose, openDiscard, isTouched, setTouched }) => {
        const [events, setEvents] = useState();
        const [form, setForm] = useState({
            entity: null,
            action: null,
        });
        const initialSet = useRef();

        useEffect(() => {
            getAutomationVariables()
                .then((events) => {
                    const newEvents = events?.map((event) => ({
                        label: getLiteral(event.description),
                        value: event.value,
                        iconType: event.value,
                        actions: event.actions?.map((action) => ({
                            label: getLiteral(action.description),
                            value: action.values,
                        })),
                    }));
                    setEvents(newEvents);
                })
                .catch((e) => {
                    console.error(e);
                    errorToast({ text: getLiteral('error_generalerror') });
                });
        }, []);

        useEffect(() => {
            if (!initialSet.current && data?.scene?.parameters?.entity && events) {
                let newForm = { entity: null, action: null };
                newForm.entity = data?.scene?.parameters?.entity;
                newForm.action = data?.scene?.parameters?.action;
                setForm(newForm);
                initialSet.current = true;
            }
        }, [
            data?.scene?.parameters?.entity,
            data?.scene?.parameters?.action,
            events,
            form,
            form?.entity,
        ]);

        const changeForm = useCallback(
            (field) => (value) => {
                setTouched(true);
                setForm((current) => ({ ...current, [field]: value }));
            },
            [setTouched],
        );

        const onDone = useCallback(() => {
            const conditions = dot.get(data, 'scene.next.0.parameters');
            const action = dot.get(data, 'scene.next.0.next.0.parameters');
            const newData = { entity: form.entity, action: form.action };
            if (!isEmptyObject(conditions) || !isEmptyObject(action)) {
                openDiscard(() => {
                    onChange('scene.parameters', newData);
                    onChange('scene.next.0.parameters', {});
                    onChange('scene.next.0.next.0.parameters', {});
                    setTouched(false);
                });
            } else {
                onChange('scene.parameters', newData);
                setTouched(false);
            }
        }, [data, form.entity, form.action, openDiscard, onChange, setTouched]);

        return (
            <AutomationBuilderSidePanel
                title={getLiteral('label_trigger')}
                subtitle={getLiteral('label_trigger_desc')}
                onClose={onClose}
                isDoneDisabled={!isTouched || !form?.action || !form?.entity}
                onDone={onDone}
            ></AutomationBuilderSidePanel>
        );
    },
);

export default AutomationBuilderConditionsSidePanel;
