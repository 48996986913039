import React, { memo, useMemo, useRef, useState, useCallback, useEffect, useContext } from 'react';
import { Text, Popover, Spacer, SearchBar, Tabs, Tooltip, Icon, Link, useTheme } from 'hoi-poi-ui';
import { useSelector, useDispatch } from 'react-redux';
import { EntityModalActions } from 'actions';
import { getDashboards } from 'services/Dashboards';
import { ENTITY_MODAL_UPDATE } from 'lib/events';
import { subscribe } from 'lib/EventBuser';
import { getLiteral } from 'utils/getLiteral';
import { removeAccents } from 'utils/strings';
import { logEvent } from 'utils/tracking';
import AbsoluteError from 'components/AbsoluteError';
import AbsoluteEmpty from 'components/AbsoluteEmpty';
import { ANALYTICS_REPORT } from 'constants/Entities';
import { FORCEBI } from 'constants/Features';

import { DashboardContext } from './DashboardContext';

const DASHBOARD_TABS = [
    {
        label: 'label_all_dashboards',
        key: 'all',
        icon: 'apps',
    },
    {
        label: 'label_dashboards',
        key: 'dashboards',
        icon: 'chart',
        permission: 'analyticsEnabled',
    },
    {
        label: 'title_sales_force',
        key: 'sfm',
        icon: 'salesForce',
        permission: 'sfmPermission',
    },
];

const AddDashboardPopover = memo(({ placement, onAdd, children }) => {
    const theme = useTheme();
    const timer = useRef();
    const dispatch = useDispatch();
    const { dashboards } = useContext(DashboardContext);
    const [dashboardList, setDashboardList] = useState();
    const [recentlyDashboardList, setRecentlyDashboardList] = useState();
    const [searchText, setSearchText] = useState();
    const [activeTab, setActiveTab] = useState(DASHBOARD_TABS[0].key);
    const [error, setError] = useState(false);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const permissions = useSelector((state) => ({
        sfmPermission: state.config?.permission?.sfm,
        analyticsEnabled:
            state.config?.permission?.analyticsPro && state.config?.userData?.lookerAnalyticsViewer,
        analyticsProCreateDashboard: state.config?.permission?.analyticsProCreateDashboard || false,
    }));
    const hasFORCEBI = useSelector((state) => state.config.flagsmith?.[FORCEBI] || false);

    const getDashboardsList = useCallback(
        (createdId) => {
            return getDashboards()
                .then((result) => {
                    setRecentlyDashboardList(
                        result
                            .filter(
                                (dashboard) =>
                                    dashboard.dateUsed && dashboard?.type?.value !== 'SFM',
                            )
                            ?.filter((dashboard) => {
                                // Exclude current dashboard tabs
                                return !dashboards.find((d) => d.id === dashboard.id);
                            })
                            .map((dashboard) => {
                                if (dashboard?.type?.value === 'SFM')
                                    dashboard.title = getLiteral(dashboard.title);
                                return dashboard;
                            })
                            .sort((a, b) => new Date(b.dateUsed) - new Date(a.dateUsed))
                            .slice(0, 4),
                    );
                    setDashboardList(
                        result
                            .map((dashboard) => {
                                if (dashboard?.type?.value === 'SFM')
                                    dashboard.title = getLiteral(dashboard.title);
                                return dashboard;
                            })
                            .sort((a, b) => a?.title?.localeCompare(b?.title)),
                    );
                    if (createdId) {
                        return result.reduce((obj, dashboard) => {
                            if (dashboard.id === createdId) obj = dashboard;
                            return obj;
                        }, {});
                    }
                    return null;
                })
                .catch((e) => {
                    console.error(e);
                    setError(true);
                });
        },
        [dashboards],
    );

    useEffect(() => {
        setError(false);
        getDashboardsList();
    }, [getDashboardsList]);

    const onChangeSearch = useCallback((event) => {
        const text = event?.target?.value || '';
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            setSearchText(text);
        }, 200);
    }, []);

    const getRow = useCallback(
        (dashboard, index) => {
            return (
                <div
                    key={index}
                    className="fm-dashboards-tabs__add-popover__row"
                    onClick={() => onAdd(dashboard)}
                >
                    <div className="fm-dashboards-tabs__add-popover__row-title">
                        <Icon name={dashboard?.type?.value === 'SFM' ? 'salesForce' : 'chart'} />
                        <Text isTruncated useTooltip>
                            {dashboard.title}
                        </Text>
                    </div>
                    {dashboard?.owner && (
                        <Text
                            className="fm-dashboards-tabs__add-popover__row-owner"
                            type="caption"
                            color="neutral700"
                            isTruncated
                            useTooltip
                        >
                            {dashboard?.owner?.value}
                        </Text>
                    )}
                </div>
            );
        },
        [onAdd],
    );

    const list = useMemo(() => {
        if (error) return null;
        const cleanText = removeAccents(searchText);
        return dashboardList
            ?.filter((dashboard) => {
                return !recentlyDashboardList.find((d) => d.id === dashboard.id);
            })
            ?.filter((dashboard) => {
                // Filtering by tab type
                switch (activeTab) {
                    case 'dashboards':
                        return (
                            ['Metabase'].includes(dashboard?.type?.value) ||
                            (['ForceBI'].includes(dashboard?.type?.value) && hasFORCEBI)
                        );
                    case 'sfm':
                        return 'SFM' === dashboard?.type?.value;
                    case 'all':
                    default:
                        return true;
                }
            })
            ?.filter((dashboard) => {
                // Exclude current dashboard tabs
                return !dashboards.find((d) => d.id === dashboard.id);
            })
            ?.filter((dashboard) => {
                // Filtering by search text
                const cleanTitle = removeAccents((dashboard?.title || '').toLowerCase());
                return cleanTitle.includes(cleanText);
            })
            .map(getRow);
    }, [
        activeTab,
        dashboardList,
        dashboards,
        error,
        getRow,
        recentlyDashboardList,
        searchText,
        hasFORCEBI,
    ]);

    const onTogglePopover = useCallback(() => {
        setPopoverVisible(!popoverVisible);
    }, [popoverVisible]);

    const onOpenCreateDashboard = useCallback(() => {
        if (!permissions.analyticsProCreateDashboard && !hasFORCEBI) return;
        onTogglePopover();
        const defaultFolder = -1; //Root folder
        dispatch(
            EntityModalActions.init({
                entity: ANALYTICS_REPORT,
                trackingSubmodule: 'forceBI',
                data: { folderId: defaultFolder },
                labels: {
                    title: getLiteral('label_create_dashboard'),
                    success: getLiteral('succes_entitycreatedsuccessfully'),
                    error: getLiteral('label_failed_create'),
                },
                version: 2,
            }),
        );
    }, [onTogglePopover, dispatch, hasFORCEBI, permissions.analyticsProCreateDashboard]);

    useEffect(() => {
        return subscribe(`${ENTITY_MODAL_UPDATE}--${ANALYTICS_REPORT.entity}`, ({ result }) => {
            if (result.code) {
                const dashboardId = result.code;
                getDashboardsList(dashboardId).then((dashboard) => {
                    if (dashboard?.id) onAdd(dashboard);
                });
            }
        });
    }, [getDashboardsList, onAdd]);

    const trackAddPopover = useCallback(() => {
        logEvent({
            event: 'dashboard',
            functionality: 'addTab',
        });
    }, []);

    const visibleOverride = useMemo(
        () => ({
            root: { visible: popoverVisible, onVisibleChange: onTogglePopover },
        }),
        [popoverVisible, onTogglePopover],
    );

    const content = useMemo(() => {
        const showEmpty = !list?.length && !error;

        return (
            <div className="fm-dashboards-tabs__add-popover__content">
                <div className="fm-dashboards-tabs__add-popover__header">
                    <div className="fm-dashboards-tabs__add-popover__title">
                        <Text type="subtitle">{getLiteral('title_add_tab_dashboard')}</Text>
                        {permissions.analyticsProCreateDashboard && hasFORCEBI && (
                            <div
                                className="fm-dashboards-tabs__add-popover__button"
                                onClick={onOpenCreateDashboard}
                            >
                                <Icon name="addCircle" color={theme.colors.orange500} />
                                <Link variation="primary" type="button">
                                    {getLiteral('label_create_dashboard')}
                                </Link>
                            </div>
                        )}
                    </div>
                    <Spacer y={3} />
                    <SearchBar
                        placeholder={getLiteral('placerholder_search_dashboards')}
                        useAsSimpleSearch={true}
                        onChange={onChangeSearch}
                    />
                </div>
                <div className="fm-dashboards-tabs__add-popover__tabs">
                    <Tabs
                        onChange={setActiveTab}
                        activeKey={activeTab}
                        tabs={DASHBOARD_TABS?.filter(
                            (tab) => !tab.permission || permissions[tab.permission],
                        ).map((tab) => ({
                            key: tab.key,
                            title: (
                                <Tooltip
                                    key={tab.key}
                                    placement="top"
                                    content={getLiteral(tab.label)}
                                >
                                    <span>
                                        <Icon
                                            className="fm-dashboards-tabs__add-popover__tabs__icon"
                                            name={tab.icon}
                                            size="large"
                                            color={
                                                activeTab === tab.key
                                                    ? theme.colors.neutral900
                                                    : theme.colors.neutral600
                                            }
                                        />
                                    </span>
                                </Tooltip>
                            ),
                        }))}
                    />
                </div>
                <div className="fm-dashboards-tabs__add-popover__list">
                    {activeTab !== 'sfm' && (
                        <>
                            {!!recentlyDashboardList?.length && !!recentlyDashboardList?.length && (
                                <>
                                    <Text type="subtitle">
                                        {getLiteral('title_recently_viewed')}
                                    </Text>
                                    <Spacer y={1} />
                                </>
                            )}
                            {recentlyDashboardList?.map(getRow)}
                            {!!recentlyDashboardList?.length && !!dashboardList?.length && (
                                <>
                                    <Spacer y={1} />
                                    <Text type="subtitle">
                                        {getLiteral('label_all_dashboards')}
                                    </Text>
                                    <Spacer y={1} />
                                </>
                            )}
                        </>
                    )}
                    {list}
                    {showEmpty && (
                        <AbsoluteEmpty
                            title={getLiteral('label_empty_screen_add_dashboards')}
                            subtitle={getLiteral('label_empty_screen_add_dashboard_desc')}
                            size="popover"
                        />
                    )}
                    {error && <AbsoluteError size="popover" />}
                </div>
            </div>
        );
    }, [
        activeTab,
        dashboardList?.length,
        error,
        getRow,
        list,
        onChangeSearch,
        permissions,
        recentlyDashboardList,
        theme.colors.orange500,
        theme.colors.neutral600,
        theme.colors.neutral900,
        hasFORCEBI,
        onOpenCreateDashboard,
    ]);

    return (
        <div onClick={trackAddPopover}>
            <Popover
                className="fm-dashboards-tabs__add-popover"
                placement={placement}
                content={content}
                overrides={visibleOverride}
            >
                {children}
            </Popover>
        </div>
    );
});

export default AddDashboardPopover;
