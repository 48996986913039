import {
    ENTITY_MODAL_INIT,
    ENTITY_MODAL_INIT_SUCCESS,
    ENTITY_MODAL_CANCEL,
} from 'constants/ActionTypes';

const initialState = {
    isOpen: false,
    active: null,
};

export default function config(state = initialState, action) {
    let active = action?.entity?.entity;
    const innerInitialState = {
        loading: false,
        loadingSave: false,
        entity: null,
        labels: {},
        fromEntity: null,
    };

    if (action.entity) {
        active = action.entity.entity;
        if (action.id) active += `-${action.id}`;
    }

    const entityState = state[active] || innerInitialState;

    switch (action.type) {
        case ENTITY_MODAL_INIT:
            return {
                ...state,
                active,
                isOpen: true,
                [active]: {
                    ...entityState,
                    entity: action.entity,
                    labels: action.labels || {},
                    hideDelete: action.hideDelete || false,
                    fromEntity: action.fromEntity,
                    extraInfo: action.extraInfo,
                    forceRefresh: action.forceRefresh || false,
                    refreshIf: action.refreshIf || null,
                    isCreateGlobal: action.isCreateGlobal || false,
                    isDetailCreateGlobal: action.isDetailCreateGlobal || false,
                    hasMiddleButton: action.hasMiddleButton || false,
                    size: action.size,
                    trackingSubmodule: action.trackingSubmodule || '',
                },
            };
        case ENTITY_MODAL_INIT_SUCCESS:
            return {
                ...state,
                [active]: {
                    ...entityState,
                    loading: false,
                    loadingSave: false,
                },
            };
        case ENTITY_MODAL_CANCEL:
            return {
                ...state,
                isOpen: false,
            };
        default:
            return state;
    }
}
