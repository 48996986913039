import { memo, useContext, useCallback, useState, useRef, useEffect, useMemo } from 'react';
import { Text } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import dot from 'utils/dot';
import ConfirmModal from 'components/ConfirmModal';
import { AutomationBuilderCanvasContext } from './AutomationBuilderCanvasContext';
import AutomationBuilderTriggerSidePanel from './sidePanelTypes/AutomationBuilderTriggerSidePanel';
import AutomationBuilderConditionsSidePanel from './sidePanelTypes/AutomationBuilderConditionsSidePanel';
import AutomationBuilderActionSidePanel from './sidePanelTypes/AutomationBuilderActionSidePanel';

const AutomationBuilderCanvasSidePanel = memo(({ data, onChange }) => {
    const { state, dispatch } = useContext(AutomationBuilderCanvasContext);
    const [discardOpen, setDiscardOpen] = useState(false);
    const [closeOpen, setCloseOpen] = useState(false);
    const [isTouched, setIsTouched] = useState();
    const discardCallback = useRef();

    const onClose = useCallback(() => {
        if (state?.nextSelected) dispatch({ type: 'select', node: state.nextSelected });
        else dispatch({ type: 'close' });
        setCloseOpen(false);
        setIsTouched(false);
    }, [dispatch, state]);

    const onCheckClose = useCallback(() => {
        if (isTouched) {
            setCloseOpen(true);
        } else {
            onClose();
        }
    }, [isTouched, onClose]);

    const closeCancel = useCallback(() => {
        setCloseOpen();
    }, []);

    const openDiscard = useCallback((cb) => {
        setDiscardOpen(true);
        discardCallback.current = cb;
    }, []);

    const onInnerChange = useCallback(
        (...props) => {
            setIsTouched(true);
            onChange(...props);
        },
        [onChange],
    );

    const onConfirmDiscard = useCallback(() => {
        discardCallback.current?.();
        closeDiscard();
    }, [closeDiscard]);

    const closeDiscard = useCallback(() => {
        setDiscardOpen();
        discardCallback.current = null;
    }, []);

    useEffect(() => {
        if (!state.nextSelected || !state.selected) return;
        if (isTouched) {
            setCloseOpen(true);
        } else dispatch({ type: 'select', node: state.nextSelected });
    }, [dispatch, isTouched, openDiscard, state.selected, state.nextSelected, onCheckClose]);

    const sidePanel = useMemo(() => {
        if (!state.selected) return null;

        const baseProps = {
            data,
            node: state.selected,
            nodePath: dot.findPathByAttr(data, 'name', state.selected.name),
            onClose: onCheckClose,
            onChange: onInnerChange,
            openDiscard,
            isTouched,
            setTouched: setIsTouched,
        };

        switch (state.selected?.type) {
            case 'trigger':
                return <AutomationBuilderTriggerSidePanel {...baseProps} />;
            case 'action':
                return <AutomationBuilderActionSidePanel {...baseProps} />;
            case 'conditions':
                return <AutomationBuilderConditionsSidePanel {...baseProps} />;
            default:
                return null;
        }
    }, [data, isTouched, onCheckClose, onInnerChange, openDiscard, state.selected]);

    if (!state.selected) return null;

    return (
        <>
            {sidePanel}
            <ConfirmModal
                overlayClassName="fm-automation-builder__inner-modal"
                isOpen={closeOpen}
                title={getLiteral('label_discard_unsaved_changes')}
                confirmText={getLiteral('action_discard_changes')}
                cancelText={getLiteral('action_cancel')}
                onConfirm={onClose}
                onClose={closeCancel}
            >
                <Text>{getLiteral('label_discard_unsaved_changes_desc')}</Text>
            </ConfirmModal>
            <ConfirmModal
                overlayClassName="fm-automation-builder__inner-modal"
                isOpen={discardOpen}
                title={getLiteral('label_reset_condition_and_action')}
                confirmText={getLiteral('action_reset_condition_and_action')}
                cancelText={getLiteral('action_cancel')}
                onConfirm={onConfirmDiscard}
                onClose={closeDiscard}
            >
                <Text>{getLiteral('label_reset_condition_and_action_desc')}</Text>
            </ConfirmModal>
        </>
    );
});

export default AutomationBuilderCanvasSidePanel;
