import React, {
    Fragment,
    useEffect,
    useState,
    memo,
    useRef,
    useMemo,
    useCallback,
    useLayoutEffect,
} from 'react';
import { Text, Icon, useTheme, Link, Button } from 'hoi-poi-ui';
import { ModalOptionsTable } from '@web/web5';
import ContentLoader from 'react-content-loader';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ConfigActions } from 'actions';
import { getLiteral, getLiteralWithParametersHtml } from 'utils/getLiteral';
import Context from 'managers/Context';
import { logEvent } from 'utils/tracking';
import { getNumberAsCurrency } from 'utils/currency';
import { getNumberAsLocaleNumber } from 'utils/numbers';
import { getRoute } from 'utils/routes';
import { getBackendBoolean } from 'utils/fm';
import { getMomentFromDateBackend, formatDateWithTimeOrNot } from 'utils/dates';
import TextWithLinks from 'components/TextWithLinks';
import { EntityChip } from 'containers/components/chips';
import TextChip from 'components/chips/TextChip';
import Row from './Row';

import './styles.scss';

const mapStateToProps = (state) => {
    return {
        productModeCode: state.config.productModeCode,
        canConfigImplementation: state.config.permission.canConfigImplementation,
        extraFieldsSchemaMap: state.config.extraFieldsSchemaMap,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setConfigWeb: bindActionCreators(ConfigActions, dispatch).setConfigWeb,
    };
};

const FeaturedFields = memo(
    ({
        entity,
        entityExtrafields = [],
        entityId,
        data,
        canConfigImplementation,
        productModeCode,
        setDynamicAsHidden,
        extraFieldsSchemaMap,
    }) => {
        const theme = useTheme();
        const colors = theme.colors;

        const [isModalOpen, setIsModalOpen] = useState(false);
        const [allExtraFields, setExtraFields] = useState(entityExtrafields);
        const [featuredIds, setFeaturedFieldsIds] = useState({});
        const [isLoading, setIsLoading] = useState(false);
        const [refresh, setRefresh] = useState(false);
        const [hasScroll, setHasScroll] = useState(false);
        const featuredFields = useRef([]);
        const containerRef = useRef();

        const renderMultipleValueList = useCallback((value) => {
            if (!value) return null;

            const values = value.trim().split(';').join(', ');

            if (values) {
                return <span className="featured-fields__multiple-list-values">{values}</span>;
            }
        }, []);

        const renderTextValue = useCallback((value) => {
            if (!value) return;
            return (
                <TextWithLinks
                    classNames={['fm-extra-fields-widget-row-value__text-field']}
                    text={value}
                />
            );
        }, []);

        const finalSections = useMemo(() => {
            return allExtraFields?.map((section) => {
                const finalSection = { ...section, description: section.descripcion };
                const sectionData = section.data.map((field, idx) => {
                    let value;
                    if (field.value || typeof field.value === 'boolean') {
                        value = field.value;
                        switch (field.dataType) {
                            case 'text':
                                value = renderTextValue(value);
                                break;
                            case 'integer':
                            case 'decimal':
                                value = getNumberAsLocaleNumber(value);
                                break;
                            case 'percent':
                                value = field.value
                                    ? `${getNumberAsLocaleNumber(field.value, {
                                          useGrouping: false,
                                          minimumFractionDigits: 2,
                                      })}%`
                                    : '-';
                                break;
                            case 'relatedValueList':
                            case 'singleValueList':
                            case 'fuzzySearchSingle':
                                if (field.value === '-1') {
                                    value = '';
                                    break;
                                }

                                let list =
                                    extraFieldsSchemaMap?.[entity.extraFieldName]?.[
                                        field.id
                                    ]?.valueListName?.toLowerCase();

                                switch (list) {
                                    case 'viewusuariosfullname':
                                    case 'viewcontactosfullname':
                                    case 'empresas':
                                    case 'expedientes':
                                        const id = Number(field.value);
                                        value = (
                                            <EntityChip
                                                key={idx}
                                                entity={list}
                                                id={id}
                                                name={field.label}
                                                remotePopover
                                                isDismissible={false}
                                            />
                                        );
                                        break;
                                    default:
                                        value = field.label;
                                        break;
                                }
                                break;
                            case 'multipleValueList':
                            case 'fuzzySearchMultiple':
                                value = field.value ? renderMultipleValueList(field.label) : '-';
                                break;
                            case 'bool':
                                value = getBackendBoolean(field.value)
                                    ? getLiteral('common_yes')
                                    : getLiteral('common_no');
                                break;
                            case 'currency':
                                const [number, currencyName] = String(value).split(' ');
                                value = getNumberAsCurrency(
                                    number,
                                    data.symbolCurrency,
                                    false,
                                    false,
                                    currencyName,
                                );
                                break;
                            case 'date':
                                value = getMomentFromDateBackend(value);
                                value = formatDateWithTimeOrNot(value, field?.includeTime);
                                break;
                        }
                    }
                    return {
                        ...field,
                        value,
                        description: (
                            <Text className="featured-fields__row__description-table">
                                {field.descripcion}
                            </Text>
                        ),
                    };
                });
                const isHidden =
                    sectionData.filter((field) => !field.hiddenByDynamism).length === 0;
                return { ...finalSection, isHidden, data: sectionData };
            });
        }, [
            allExtraFields,
            data.symbolCurrency,
            entity.extraFieldName,
            extraFieldsSchemaMap,
            renderMultipleValueList,
            renderTextValue,
        ]);

        const setFields = useCallback((finalSections) => {
            const fields = [];
            let finalFeaturedFields = {};
            finalSections.forEach((section) => fields.push(...section.data));

            fields.forEach((field) => {
                if (getBackendBoolean(field.isRelevant)) {
                    finalFeaturedFields = { ...finalFeaturedFields, [field.id]: true };
                }
                return finalFeaturedFields;
            });
            featuredFields.current = fields.filter((field) => getBackendBoolean(field.isRelevant));
            setFeaturedFieldsIds(finalFeaturedFields);
        }, []);

        useEffect(() => {
            if (refresh) {
                setIsLoading(true);
                const manager = Context.entityManager.getEntitiesManager(entity);
                Context.entityDetailManager
                    .getExtraFields(entity, manager, entityId, setDynamicAsHidden)
                    .then((data) => {
                        setExtraFields(data?.extraFields);
                        setIsLoading(false);
                    })
                    .catch((err) => {
                        console.error(err);
                        setExtraFields(entityExtrafields);
                        setIsLoading(false);
                    });
                setRefresh(false);
            }
            setFields(finalSections);
        }, [
            entity,
            entityExtrafields,
            entityId,
            finalSections,
            refresh,
            setFields,
            setDynamicAsHidden,
        ]);

        useEffect(() => {
            // Updating extra fields  if modified
            setExtraFields(entityExtrafields);
        }, [setExtraFields, entityExtrafields]);

        useLayoutEffect(() => {
            if (allExtraFields) {
                const containerEl = containerRef?.current;
                setTimeout(() => {
                    setHasScroll(
                        containerEl?.scrollHeight - containerEl?.scrollTop !==
                            containerEl?.clientHeight,
                    );
                });
            }
        }, [allExtraFields, setHasScroll]);

        const header = useMemo(() => {
            return (
                <div className="featured-fields__header">
                    <Text bold>{getLiteral('label_featured_customfields')}</Text>
                </div>
            );
        }, []);

        const emptyContent = useMemo(() => {
            let emptyComp = (
                <Text color="neutral600" className="featured-fields__body__title">
                    {getLiteral('label_no_featured_customfields_feature_fields')}
                </Text>
            );

            if (!allExtraFields.length) {
                emptyComp =
                    canConfigImplementation && productModeCode !== 'business' ? (
                        <Text color="neutral600" className="featured-fields__body__title">
                            {getLiteralWithParametersHtml(
                                'label_no_featured_customfields_create_field',
                                [
                                    [
                                        <span>
                                            <Link
                                                href={getRoute(
                                                    `/settings/fields?showCrud=true&tab=${entity?.extraFieldTab}`,
                                                )}
                                                className="featured-fields__body__link"
                                                onClick={() =>
                                                    logEvent({
                                                        event: entity?.trueName,
                                                        submodule: 'infoWidget',
                                                        functionality: 'createField',
                                                    })
                                                }
                                                bold
                                            >
                                                {getLiteral(
                                                    'label_no_featured_customfields_create_field_link',
                                                )}
                                            </Link>
                                        </span>,
                                    ],
                                ],
                            )}
                        </Text>
                    ) : (
                        <Text color="neutral600" className="featured-fields__body__title">
                            {getLiteral('label_no_featured_customfields')}
                        </Text>
                    );
            }

            return (
                <div className="featured-fields__body--empty">
                    <div>
                        <Icon name="starBorder" size="big" color={colors.neutral600} />
                    </div>
                    {emptyComp}
                </div>
            );
        }, [
            allExtraFields.length,
            colors.neutral600,
            canConfigImplementation,
            productModeCode,
            entity?.extraFieldTab,
            entity?.trueName,
        ]);

        const content = useMemo(() => {
            const rows = [];
            finalSections.forEach((section) => rows.push(...section.data));
            const featuredRows = rows.filter(
                (field) =>
                    getBackendBoolean(field.isRelevant) &&
                    !getBackendBoolean(field.hiddenByDynamism),
            );
            if (!featuredRows.length || !allExtraFields.length) return emptyContent;

            return featuredRows.map((field, index) => {
                return <Row key={index} description={field.description} value={field.value} />;
            });
        }, [allExtraFields.length, emptyContent, finalSections]);

        const onChangeFeatured = useCallback(
            (field, isRelevant) => {
                if (isRelevant) {
                    logEvent({
                        event: entity?.trueName,
                        submodule: 'extraFieldsModal',
                        functionality: 'favorite',
                    });
                    featuredFields.current.push(field);
                } else {
                    logEvent({
                        event: entity?.trueName,
                        submodule: 'extraFieldsModal',
                        functionality: 'unfavorite',
                    });
                    featuredFields.current = featuredFields.current.filter(
                        (featured) => featured.id !== field.id,
                    );
                }
                setFeaturedFieldsIds({
                    ...featuredIds,
                    [field.id]: isRelevant,
                });
            },
            [entity?.trueName, featuredIds],
        );

        const onConfirm = useCallback(() => {
            setIsLoading(true);
            const ids = featuredFields.current.map((field) => field.id);
            Context.domainManager
                .setRelevantFields({
                    extraFieldNames: ids,
                    fieldsEntity: entity?.dbName,
                })
                .then(() => {
                    setIsModalOpen(false);
                    setIsLoading(false);
                    setRefresh(true);
                })
                .catch((error) => {
                    setIsModalOpen(false);
                    setIsLoading(false);
                    setRefresh(true);
                    console.error(error);
                });
        }, [entity]);

        const onCancel = useCallback(() => {
            setIsModalOpen(false);
            setFields(finalSections);
        }, [finalSections, setFields]);

        const onClickInput = useCallback(() => {
            logEvent({
                event: entity?.trueName,
                submodule: 'extraFieldsModal',
                functionality: 'search',
            });
        }, [entity]);

        const onClickShow = useCallback(() => {
            logEvent({
                event: entity?.trueName,
                submodule: 'infoWidget',
                functionality: 'showExtraFields',
            });
            setIsLoading(false);
            setIsModalOpen(true);
        }, [entity?.trueName]);

        const loader = useMemo(
            () => (
                <ContentLoader
                    height={180}
                    width={400}
                    speed={2}
                    backgroundColor={theme.colors.neutral200}
                    foregroundColor={theme.colors.neutral300}
                >
                    <rect x="0" y="20" rx="3" ry="3" width={80} height="8" />
                    <rect x="120" y="20" rx="3" ry="3" width={200} height="8" />
                    <rect x="0" y="50" rx="3" ry="3" width={80} height="8" />
                    <rect x="120" y="50" rx="3" ry="3" width={200} height="8" />
                    <rect x="0" y="80" rx="3" ry="3" width={80} height="8" />
                    <rect x="120" y="80" rx="3" ry="3" width={200} height="8" />
                    <rect x="0" y="110" rx="3" ry="3" width={80} height="8" />
                    <rect x="120" y="110" rx="3" ry="3" width={200} height="8" />
                    <rect x="0" y="140" rx="3" ry="3" width={80} height="8" />
                    <rect x="120" y="140" rx="3" ry="3" width={200} height="8" />
                    <rect x="0" y="170" rx="3" ry="3" width={80} height="8" />
                    <rect x="120" y="170" rx="3" ry="3" width={200} height="8" />
                </ContentLoader>
            ),
            [theme.colors.neutral200, theme.colors.neutral300],
        );

        const showFieldsClass = useMemo(
            () =>
                classnames('featured-fields__show-fields', {
                    'featured-fields__show-fields--scrolled': hasScroll,
                }),
            [hasScroll],
        );

        const showMoreContent = useCallback(
            () => (
                <div className={showFieldsClass}>
                    <Button
                        className="featured-fields__show-fields__see-all"
                        type="secondary"
                        onClick={onClickShow}
                    >
                        {getLiteral('action_see_all_customfields')}
                    </Button>
                </div>
            ),
            [onClickShow, showFieldsClass],
        );

        return (
            <Fragment>
                <div className="featured-fields__container">
                    {header}
                    <div ref={containerRef} className="featured-fields__body">
                        {isLoading && loader}
                        {!isLoading && content}
                    </div>
                    {!isLoading && !!allExtraFields.length && showMoreContent()}
                </div>
                <ModalOptionsTable
                    advice={getLiteral('label_help_featured_fields_modal')}
                    cancelText={getLiteral('action_cancel')}
                    onCancel={onCancel}
                    onConfirm={onConfirm}
                    placeholderSearch={getLiteral('label_search_field')}
                    confirmText={getLiteral('label_accept')}
                    isOpen={isModalOpen}
                    sections={finalSections}
                    title={getLiteral('label_preview_customfields')}
                    isLoading={isLoading}
                    featuredIds={featuredIds}
                    featuredFields={featuredFields.current}
                    onChangeFeatured={onChangeFeatured}
                    entity={entity?.trueName}
                    onClickInput={onClickInput}
                />
            </Fragment>
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedFields);
