import { memo, useEffect, useReducer, useCallback, useMemo, useRef, useState } from 'react';
import { Modal, Text } from 'hoi-poi-ui';

import { getAutomation, putAutomation, createAutomation } from 'services/AutomationsService';

import { successToast, errorToast } from 'utils/toast';
import { getLiteral } from 'utils/getLiteral';
import { ensureRoute } from 'utils/routes';
import dot from 'utils/dot';
import ConfirmModal from 'components/ConfirmModal';

import AutomationBuilderHeader from './AutomationBuilderHeader';
import AutomationBuilderCanvas from './AutomationBuilderCanvas';
import AutomationBuilderFooter from './AutomationBuilderFooter';

import AutomationBuilderHeaderModalForm from './components/AutomationBuilderHeaderModalForm';

import './styles.scss';

const initialState = {
    id: null,
    data: null,
    open: false,
    loading: true,
    error: false,
};

function reducer(state, action) {
    switch (action.type) {
        case 'load':
            return {
                ...state,
                loading: false,
                error: false,
                open: true,
                data: action.data,
                id: action.id,
            };
        case 'changeData':
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data,
                },
            };
        case 'changeField':
            return {
                ...state,
                data: dot.set(state.data, action.field, action.value),
            };
        case 'loading':
            return {
                ...state,
                loading: true,
                error: false,
                data: null,
            };
        case 'error':
            return {
                ...state,
                data: null,
                loading: false,
                error: true,
            };
        case 'close':
            return {
                ...state,
                open: false,
            };
        default:
            throw new Error('No action provided');
    }
}

const AutomationBuilder = memo(({ onRef, onRefresh }) => {
    const modalRef = useRef();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [isTouched, setIsTouched] = useState(false);
    const [discardOpen, setDiscardOpen] = useState(false);

    useEffect(() => {
        onRef &&
            onRef({
                open(id, options) {
                    setIsTouched(false);
                    if (id) {
                        getAutomation(id)
                            .then((data) => {
                                if (options?.duplicate) {
                                    dispatch({
                                        type: 'load',
                                        data: {
                                            ...data,
                                            name: '',
                                            description: '',
                                            enabled: true,
                                        },
                                    });
                                } else {
                                    dispatch({ type: 'load', data, id });
                                }
                            })
                            .catch((e) => {
                                console.error(e);
                                errorToast({ text: getLiteral('error_generalerror') });
                            });
                    } else {
                        // Default hardcoded scene
                        dispatch({
                            type: 'load',
                            data: {
                                type: 'trigger',
                                enabled: true,
                                parameters: {},
                                scene: {
                                    type: 'trigger',
                                    name: 'entity_crud',
                                    parameters: {},
                                    next: [
                                        {
                                            type: 'conditions',
                                            name: 'conditions_logic',
                                            parameters: {},
                                            next: [
                                                {
                                                    type: 'action',
                                                    name: 'update_entity_related',
                                                    parameters: {},
                                                },
                                            ],
                                        },
                                    ],
                                },
                            },
                            id: null,
                        });
                    }
                },
            });
    }, [onRef]);

    const onRequestClose = useCallback(() => {
        if (isTouched) {
            setDiscardOpen(true);
        } else {
            dispatch({ type: 'close' });
            if (window.location.href.includes('settings/automations')) {
                ensureRoute('/settings/automations');
            }
        }
    }, [isTouched]);

    const onChange = useCallback((field, value) => {
        dispatch({ type: 'changeField', field, value });
        setIsTouched(true);
    }, []);

    const onSave = useCallback(() => {
        let call;
        if (state.id) {
            call = () => putAutomation(state.id, state.data);
        } else {
            call = () => createAutomation(state.data);
        }

        if (!state.data?.name) {
            onEditHeader();
            return;
        }

        call()
            .then(() => {
                onRefresh();
                dispatch({ type: 'close' });
                if (window.location.href.includes('settings/automations')) {
                    ensureRoute('/settings/automations');
                }

                let toastLiteral = 'succes_entitycreatedsuccessfully';
                if (state.id) toastLiteral = 'succes_entityupdatedsuccessfully';

                successToast({
                    text: getLiteral(toastLiteral),
                });
            })
            .catch((e) => {
                console.error(e);
                errorToast({ text: getLiteral('error_generalerror') });
            });
    }, [onEditHeader, onRefresh, state.data, state.id]);

    const handleRef = useCallback((ref) => {
        modalRef.current = ref;
    }, []);

    const onEditHeader = useCallback(() => {
        modalRef.current?.open(state.data);
    }, [state.data]);

    const onSaveHeaderForm = useCallback((data) => {
        dispatch({ type: 'changeData', data });
    }, []);

    const onClose = useCallback(() => {
        dispatch({ type: 'close' });
        if (window.location.href.includes('settings/automations')) {
            ensureRoute('/settings/automations');
        }
        setDiscardOpen(false);
        setIsTouched(false);
    }, [dispatch]);

    const closeCancel = useCallback(() => {
        setDiscardOpen();
    }, []);

    const content = useMemo(() => {
        if (!state.open) return null;
        return (
            <div className="fm-automation-builder__content">
                <AutomationBuilderHeader
                    onClose={onRequestClose}
                    onEdit={onEditHeader}
                    title={state.data?.name}
                    description={state.data?.description}
                />
                <AutomationBuilderCanvas id={state.id} data={state.data} onChange={onChange} />
                <AutomationBuilderFooter
                    onClose={onRequestClose}
                    onSave={onSave}
                    saveDisabled={!isTouched}
                />
                <AutomationBuilderHeaderModalForm onRef={handleRef} onSave={onSaveHeaderForm} />
                <ConfirmModal
                    overlayClassName="fm-automation-builder__inner-modal"
                    isOpen={discardOpen}
                    title={getLiteral('label_discard_unsaved_changes')}
                    confirmText={getLiteral('action_discard_changes')}
                    cancelText={getLiteral('action_cancel')}
                    onConfirm={onClose}
                    onClose={closeCancel}
                >
                    <Text>{getLiteral('label_discard_unsaved_changes_desc')}</Text>
                </ConfirmModal>
            </div>
        );
    }, [
        closeCancel,
        discardOpen,
        handleRef,
        isTouched,
        onChange,
        onClose,
        onEditHeader,
        onRequestClose,
        onSave,
        onSaveHeaderForm,
        state.data,
        state.id,
        state.open,
    ]);

    return (
        <Modal
            className="fm-automation-builder"
            overlayClassName="fm-automation-builder__overlay"
            onRequestClose={onRequestClose}
            useHeader={false}
            isOpen={state.open}
        >
            {content}
        </Modal>
    );
});

export default AutomationBuilder;
