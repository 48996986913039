import React, { memo, useMemo } from 'react';
import { connect } from 'react-redux';
import { axios } from 'services';
import { Web5Provider } from '@web/web5';
import Context from 'managers/Context';
import { NEW_API_URL, B2_URL, FORCE_BI_URL, PATH_IMAGE } from 'constants/Environment';
import * as FmBridgeBackend from '@web/fm-bridge-backend';
import { logEvent } from 'utils/tracking';
import { getEntityFromString } from 'utils/getEntityFromString';
import {
    COMPANIES,
    CONTACTS,
    OPPORTUNITIES,
    ACTIVITIES,
    AGENDA,
    SALESORDERS,
    DOCUMENTS,
    PRODUCTS,
    KPIS,
    BILLBOARD,
    CAMPAIGNS,
    CONVERSATIONS,
    METRICS,
    ANALYTICS_REPORT,
    DASHBOARD,
} from 'constants/Entities';

const DEFAULT_THEME = {};
const Web5ProviderContext = ({ children, literals, theme = DEFAULT_THEME }) => {
    const web5Context = useMemo(
        () => ({
            token: Context.config?.token,
            axiosInstance: axios,
            apiBaseURL: NEW_API_URL,
            b2URL: B2_URL,
            forceBIURL: FORCE_BI_URL,
            pathImage: PATH_IMAGE,
            platform: 101,
            language: Context.config?.userData?.langISOInterface,
            locale: Context.config?.userData?.locale,
            email: Context.config?.userData?.email,
            implementation: parseInt(Context.config?.userData?.idImplementacion, 10),
            locales: literals,
            bridgeBackend: FmBridgeBackend,
            logEvent,
            entities: {
                COMPANIES,
                CONTACTS,
                OPPORTUNITIES,
                ACTIVITIES,
                AGENDA,
                SALESORDERS,
                DOCUMENTS,
                PRODUCTS,
                KPIS,
                BILLBOARD,
                CAMPAIGNS,
                CONVERSATIONS,
                METRICS,
                ANALYTICS_REPORT,
                DASHBOARD,
            },
            getWeb4EntityFromString: getEntityFromString,
        }),
        [literals],
    );
    return (
        <Web5Provider context={web5Context} theme={theme || DEFAULT_THEME}>
            {children}
        </Web5Provider>
    );
};

const mapStateToProps = (state) => {
    return {
        literals: state.literal?.literals,
    };
};

export default memo(connect(mapStateToProps)(Web5ProviderContext));
