import { ACTIVITIES, COMPANIES, CONTACTS, OPPORTUNITIES, SALESORDERS } from 'constants/Entities';

export function findNodeByType(node, id) {
    if (node.name === id) return node;
    if (Array.isArray(node.next)) {
        for (const child of node.next) {
            const result = findNodeByType(child, id);
            if (result) return result;
        }
    }

    return null;
}

export function findParentById(tree, targetId) {
    function traverse(node, parent) {
        if (node.name === targetId) {
            return parent;
        }
        if (node.next) {
            for (const child of node.next) {
                const result = traverse(child, node);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    }

    return traverse(tree, tree);
}

export const AUTOMATION_ENTITIES = [COMPANIES, ACTIVITIES, OPPORTUNITIES, CONTACTS, SALESORDERS];
