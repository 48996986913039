function transformUrl(url) {
    return url.replace(/(\w+)(-\w+)?\./, '$13$2.');
}

const regex = /\.(aspx|ashx)\b/i;
const web3URL = transformUrl(window.location.hostname);
if (
    regex.test(window.location.pathname) &&
    (window.location.hostname !== web3URL || window.location.hostname === 'localhost')
) {
    const searchParams = new URLSearchParams(new URL(window.location.href).search);
    const urlObj = new URL(window.location.href);
    // Redirects to web4 pages
    switch (window.location.pathname?.toLowerCase()) {
        case '/changepassword.aspx':
            window.location.href = `/?hash=${searchParams.get('prl')}#/change-password`;
            break;
        case '/changepasswordemail.aspx':
            urlObj.pathname = '/';
            urlObj.hash = '/recover-password';
            window.location.href = urlObj.toString();
            break;
        case '/page.aspx':
            urlObj.pathname = '/';
            window.location.href = urlObj.toString();
            break;
        case '/views/companies.aspx':
            urlObj.pathname = '/';
            urlObj.hash = '/companies';
            window.location.href = urlObj.toString();
            break;
        case '/views/opportunitylist.aspx':
            urlObj.pathname = '/';
            urlObj.hash = '/opportunities';
            window.location.href = urlObj.toString();
            break;
        case '/views/activitylist.aspx':
            urlObj.pathname = '/';
            urlObj.hash = '/activities';
            window.location.href = urlObj.toString();
            break;
        case '/views/calendar.aspx':
            urlObj.pathname = '/';
            urlObj.hash = '/agenda';
            window.location.href = urlObj.toString();
            break;
        case '/views/productlist.aspx':
            urlObj.pathname = '/';
            urlObj.hash = '/products';
            window.location.href = urlObj.toString();
            break;
        case '/views/contactslist.aspx':
            urlObj.pathname = '/';
            urlObj.hash = '/contacts';
            window.location.href = urlObj.toString();
            break;
        case '/views/custompage.aspx':
            urlObj.pathname = '/';
            urlObj.hash = '/custom-page';
            window.location.href = urlObj.toString();
            break;
        case '/login.aspx':
            window.location.href = `/?user=${encodeURIComponent(
                searchParams.get('u'),
            )}&token=${searchParams.get('h')}#/auth`;
            break;
        case '/views/settings.aspx':
            urlObj.pathname = '/';
            urlObj.hash = '/settings';
            window.location.href = urlObj.toString();
        default:
            urlObj.hostname = web3URL;
            urlObj.hash = '';
            window.location.href = urlObj.toString();
            break;
    }
}
