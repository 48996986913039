import { memo } from 'react';
import { Text, Icon, Button } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

const AutomationBuilderSidePanel = memo(
    ({ title, subtitle, onClose, children, onDone, isDoneDisabled }) => {
        return (
            <div className="fm-automation-builder__side-panel">
                <div className="fm-automation-builder__side-panel__header">
                    <div className="fm-automation-builder__side-panel__title">
                        <Text type="h6">{title}</Text>
                        <Icon name="close" size="large" onClick={onClose} />
                    </div>
                    <Text color="neutral700">{subtitle}</Text>
                </div>
                <div className="fm-automation-builder__side-panel__content">{children}</div>
                <div className="fm-automation-builder__side-panel__footer">
                    {onDone && (
                        <Button onClick={onDone} isDisabled={isDoneDisabled}>
                            {getLiteral('action_done')}
                        </Button>
                    )}
                </div>
            </div>
        );
    },
);

export default AutomationBuilderSidePanel;
