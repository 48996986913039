import React, { memo, useMemo, useRef, useState, useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loader } from 'hoi-poi-ui';

import { LookerService } from 'services';

import { goDefaultPage, hashHistory } from 'utils/routes';
import { getLiteral } from 'utils/getLiteral';

import { ANALYTICS_REPORT } from 'constants/Entities';

import Breadcrumb from 'components/Breadcrumb';
import ContentLayout from 'containers/components/layouts/ContentLayout';

import MetricsHeader from './MetricsHeader';
import IFrame from 'components/IFrame';

import DashboardForceBI from 'containers/DashboardForceBI';

const MetricsDashboardView = memo(() => {
    const login = useSelector((state) => state.config.login);
    const analyticsEnabled = useSelector(
        (state) =>
            state.config?.permission?.analyticsPro && state.config?.userData?.lookerAnalyticsViewer,
    );
    const analyticsProCreateDashboard = useSelector(
        (state) => state.config?.permission?.analyticsProCreateDashboard,
    );

    let { id, mode } = useParams();
    const [url, setUrl] = useState();
    const [inExplorer, setInExplorer] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [breadcrumb, setBreadcrumb] = useState([]);
    const [dashboardData, setDashboardData] = useState(null);
    const breadcrumbRef = useRef();

    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            LookerService.getDashboardURL(id),
            LookerService.getDashboardPath(id),
            LookerService.getDashboard(id),
        ])
            .then(([data, path = [], dashboard]) => {
                if (!data?.url) setError(true);
                else setUrl(data.url);

                setDashboardData(dashboard);

                setBreadcrumb([
                    { id: -1, name: getLiteral('label_dashboards'), shared: true },
                    ...(path || []),
                    { id: dashboard.id, name: dashboard.title },
                ]);
            })
            .catch((e) => {
                console.error(e);
                setError(true);
            })
            .then(() => {
                setIsLoading(false);
            });
    }, [id, mode]);

    useEffect(() => {
        window.addEventListener('message', function (event) {
            if (
                event.source !== document.getElementById('metrics__iframe')?.contentWindow ||
                event.origin !== 'https://forcemanager.eu.looker.com' ||
                !event.data
            )
                return;

            try {
                const data = JSON.parse(event.data);
                switch (data.type) {
                    case 'page:changed':
                        if (data.page.type === 'explore') setInExplorer(true);
                        else setInExplorer(false);
                        break;
                }
            } catch (e) {
                console.error('Error parsing Looker message data', e);
            }
        });
    }, []);

    const onClickBreadcrumb = useCallback(
        (item) => {
            if (item.id === -1) hashHistory.push(`/metrics/dashboards`);
            else if (String(item.id) === id) {
                LookerService.getDashboardURL(id)
                    .then((data) => {
                        if (!data?.url) setError(true);
                        else {
                            setUrl(data.url);
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                        setError(true);
                    });
            } else hashHistory.push(`/metrics/dashboards/${item.id}`);
        },
        [id],
    );

    const breadcrumbs = useMemo(() => {
        return (
            <div ref={breadcrumbRef} className="fm-metrics__detail-breadcrumb">
                {breadcrumb?.map((current, idx) => {
                    return (
                        <Breadcrumb
                            key={current.id}
                            onClick={onClickBreadcrumb}
                            folder={current}
                            folderSelected={{ id: inExplorer ? undefined : parseInt(id, 10) }}
                            noArrow={idx === breadcrumb.length - 1}
                        />
                    );
                })}
            </div>
        );
    }, [breadcrumb, id, inExplorer, onClickBreadcrumb]);

    const params = useMemo(() => ({ analyticsProCreateDashboard }), [analyticsProCreateDashboard]);

    const idForceBI = useMemo(() => {
        if (!url) return null;

        const regex = /\/dashboard\/(\d+)/;
        const match = url.match(regex);

        if (match && match[1]) {
            const number = match[1];
            return number;
        }
        return null;
    }, [url]);

    if (!login) return null;
    if (!analyticsEnabled) return goDefaultPage();

    return (
        <ContentLayout header={<MetricsHeader />}>
            {breadcrumbs}
            {isLoading && (
                <div className="fm-metrics__loader">
                    <Loader size="huge" />
                </div>
            )}
            {!idForceBI && !isLoading && (
                <div className="fm-metrics__iframe">
                    <IFrame
                        id="metrics__iframe"
                        isLoading={isLoading}
                        url={url}
                        error={error}
                        withToken
                        params={params}
                    />
                </div>
            )}
            {idForceBI && !isLoading && (
                <div className="fm_metrics_forceBI_container">
                    <DashboardForceBI
                        entity={ANALYTICS_REPORT}
                        id={idForceBI}
                        title={dashboardData.title}
                    />
                </div>
            )}
        </ContentLayout>
    );
});

export default memo(MetricsDashboardView);
