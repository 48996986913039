import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import CompanyChip from 'containers/components/chips/CompanyChip';
import OpportunityChip from 'containers/components/chips/OpportunityChip';
import ContactChip from 'containers/components/chips/ContactChip';
import UserChip from 'containers/components/chips/UserChip';
import TextChip from 'components/chips/TextChip';

class EntityChip extends PureComponent {
    state = {};

    render() {
        const {
            id,
            idIconField,
            name,
            surname,
            subtitle,
            remotePopover,
            className,
            classNamePopper,
            disableClick,
            onClick,
            onMouseEnter,
            onMouseLeave,
            onClickPopover,
            isDismissible,
            onClickDismissible,
            entity,
            color,
            noCache,
        } = this.props;

        const commonProps = {
            className,
            onClick,
            onMouseEnter,
            onMouseLeave,
            onClickPopover,
            isDismissible,
            onClickDismissible,
            idIconField,
            classNamePopper,
            disableClick,
            noCache,
        };

        let chip = <TextChip text={name} {...commonProps} />;

        switch (entity) {
            case 'empresas':
            case 'companies':
            case 'companyrates':
                chip = (
                    <CompanyChip
                        idCompany={id}
                        nameCompany={name}
                        remotePopover={remotePopover}
                        color={color}
                        {...commonProps}
                    />
                );
                break;
            case 'contacts':
            case 'contactos':
            case 'viewcontactosfullname':
                chip = (
                    <ContactChip
                        idContact={id}
                        nameContact={name}
                        surname={surname}
                        subtitle={subtitle}
                        remotePopover={remotePopover}
                        color={color}
                        {...commonProps}
                    />
                );
                break;
            case 'expedientes':
            case 'opportunities':
                chip = (
                    <OpportunityChip
                        idOpportunity={id}
                        nameOpportunity={name}
                        remotePopover={remotePopover}
                        {...commonProps}
                    />
                );
                break;
            case 'usuarios':
            case 'users':
            case 'viewusuariosfullname':
                chip = (
                    <UserChip
                        idUser={id}
                        username={name}
                        remotePopover={remotePopover}
                        color={color}
                        {...commonProps}
                    />
                );
                break;
        }

        return chip;
    }
}

EntityChip.propTypes = {
    entity: PropTypes.string,
    id: PropTypes.any,
    name: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    isDismissible: PropTypes.bool,
    onClickDismissible: PropTypes.func,
    idIconField: PropTypes.any,
};

export default EntityChip;
