import { memo, useState, useCallback } from 'react';
import { Button, Icon, Tooltip, Text, useTheme } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import copyFn from 'utils/copy';
import { successToast } from 'utils/toast';
const DanaBoxActions = ({ editor, onRefresh, onClose, onDiscard, textRef }) => {
    const theme = useTheme();
    const [isDiscarting, setIsDiscarting] = useState(false);

    const onInsert = useCallback(() => {
        let { to } = editor?.state?.selection;
        editor
            .chain()
            .focus()
            .insertContentAt(
                to,
                {
                    type: 'paragraph',
                    content: [
                        {
                            type: 'text',
                            text: textRef.current,
                        },
                    ],
                },
                {
                    updateSelection: true,
                    parseOptions: {
                        preserveWhitespace: 'full',
                    },
                },
            )
            .run();
        onClose();
        setIsDiscarting(false);
    }, [editor, onClose, textRef]);

    const onCopy = useCallback(() => {
        copyFn(textRef.current);
        successToast({ text: getLiteral('label_copy_ok') });
    }, [textRef]);

    const onReplace = useCallback(() => {
        let { from, to, empty } = editor?.state?.selection;
        const transformedText = textRef.current?.replaceAll('\n', '<br>');
        if (empty) {
            editor
                .chain()
                .clearContent(true)
                .insertContent(transformedText, {
                    updateSelection: true,
                    parseOptions: {
                        preserveWhitespace: 'full',
                    },
                })
                .run();
        } else {
            editor
                .chain()
                .insertContentAt({ from, to }, transformedText, {
                    updateSelection: true,
                    parseOptions: {
                        preserveWhitespace: 'full',
                    },
                })
                .run();
        }
        onClose();
        setIsDiscarting(false);
    }, [editor, onClose, textRef]);

    const onCancel = useCallback(() => {
        setIsDiscarting(true);
    }, []);

    const onGoBack = useCallback(() => {
        setIsDiscarting(false);
    }, []);

    const onInnerDiscard = useCallback(() => {
        setIsDiscarting(false);
        onDiscard();
    }, [onDiscard]);

    if (isDiscarting) {
        return (
            <div className="fm-ee_dana-box__action-discarting">
                <div className="fm-ee_dana-box__action-discarting__title">
                    <Icon name="warning" color={theme.colors.yellow500} />
                    <Text>{getLiteral('label_discard_response')}</Text>
                </div>
                <div className="fm-ee_dana-box__action-discarting__buttons">
                    <Button type="secondary" onClick={onGoBack}>
                        {getLiteral('action_back')}
                    </Button>
                    <Button onClick={onInnerDiscard}>{getLiteral('action_discard_changes')}</Button>
                </div>
            </div>
        );
    }

    return (
        <div className="fm-ee__dana-box__action">
            <Tooltip placement="top" content={getLiteral('label_new_proposal')}>
                <span>
                    <Icon name="refresh" onClick={onRefresh} />
                </span>
            </Tooltip>
            <Tooltip placement="top" content={getLiteral('action_copy')}>
                <span>
                    <Icon name="contentCopy" onClick={onCopy} />
                </span>
            </Tooltip>
            <Button type="terciary" onClick={onCancel}>
                {getLiteral('action_cancel')}
            </Button>
            <Button type="secondary" onClick={onInsert}>
                {getLiteral('action_insert_below')}
            </Button>
            <Button onClick={onReplace}>{getLiteral('action_replace')}</Button>
        </div>
    );
};

export default memo(DanaBoxActions);
