import {
    ENTITY_DETAIL_OPEN,
    ENTITY_DETAIL_INIT,
    ENTITY_DETAIL_INIT_SUCCESS,
    ENTITY_DETAIL_INIT_ERROR,
    ENTITY_DETAIL_CLOSE,
    ENTITY_UPDATE_LOCALLY,
    ENTITY_DETAIL_SET_NEXT_DETAIL,
    ENTITY_DETAIL_RESET,
    ENTITY_DETAIL_RESET_NEXT_DETAIL,
    ENTITY_DETAIL_REFRESH_FROM_CRUD,
    ENTITY_DETAIL_CHANGE_SECTION,
    ENTITY_DETAIL_LOADING,
    ENTITY_UPDATE_EXTRA,
    ENTITY_DETAIL_SET_TABS,
    CLEAR,
} from 'constants/ActionTypes';

const initialState = {
    isOpen: false,
    active: null,
    nextDetailEntityType: null,
    nextDetail: null,
    forceRefresh: false,
    purge: false,
    tabs: {},
};

const purgeTabsDetails = (state) => {
    // For performance, on closing an entity's detail we remove all the stored data
    // for all the entity details that have been opened from the primary tab
    Object.entries(state.tabs).forEach(([key, { primary }]) => {
        if (!primary) delete state[key];
    });
    return state;
};

export default function config(state = initialState, action) {
    let active = state.active;

    if (action.entity) {
        active = action.entity;
        if (action.id) active += `-${action.id}`;
        if (action.extraId) active += `-${action.extraId}`;
    }

    // Default specific entity state
    const entityState = state[active] || {
        loading: false,
        error: false,
        errors: {},
        entity: null,
        route: null,
        id: null,
        extraId: null,
        data: {},
        hasCrudInDetail: false,
        section: '0',
    };

    switch (action.type) {
        case ENTITY_DETAIL_OPEN:
            // FIXME we need all the logic as entityCrud. For the moment we are only
            // FIXME opening the window
            return {
                ...state,
                isOpen: true,
                purge: false,
                tabs: action.tabs || state.tabs,
            };
        case ENTITY_DETAIL_LOADING:
            return {
                ...state,
                tabs: action.tabs || state.tabs,
                [active]: {
                    ...entityState,
                    loading: action.loading,
                },
            };
        case ENTITY_DETAIL_INIT:
            return {
                ...state,
                isOpen: true,
                forceRefresh: false,
                active,
                tabs: action.tabs,
                [active]: {
                    ...entityState,
                    entity: action.entity,
                    route: action.route,
                    id: action.id,
                    loading: true,
                    hasCrudInDetail: action.hasCrudInDetail || false,
                    extra: action.extra,
                    avoidRedirects: action.avoidRedirects,
                },
                purge: false,
            };
        case ENTITY_DETAIL_INIT_SUCCESS:
            return {
                ...state,
                [active]: {
                    ...entityState,
                    loading: false,
                    data: action.data,
                },
            };
        case ENTITY_DETAIL_INIT_ERROR:
            return {
                ...state,
                [active]: {
                    ...entityState,
                    loading: false,
                    error: true,
                    errors: action.errors || {},
                },
            };
        case ENTITY_DETAIL_CLOSE:
            const purgedState =
                Object.entries(state.tabs).length > 0 && action.purgeTabs
                    ? purgeTabsDetails(state)
                    : state;
            return {
                ...purgedState,
                isOpen: false,
                purge: action.purge,
                tabs: action.tabs || {},
            };
        case ENTITY_UPDATE_LOCALLY:
            const newData = {
                ...entityState.data,
                ...action.fields,
            };
            return {
                ...state,
                [active]: {
                    ...entityState,
                    data: newData,
                },
            };
        case ENTITY_DETAIL_RESET:
            return {
                ...initialState,
            };
        case ENTITY_DETAIL_SET_NEXT_DETAIL:
            return {
                ...state,
                nextDetailEntityType: action.entityType,
                nextDetail: action.data,
            };
        case ENTITY_DETAIL_RESET_NEXT_DETAIL:
            return {
                ...state,
                nextDetailEntityType: null,
                nextDetail: null,
            };
        case ENTITY_DETAIL_REFRESH_FROM_CRUD:
            return {
                ...state,
                forceRefresh: true,
            };
        case ENTITY_DETAIL_CHANGE_SECTION:
            return {
                ...state,
                [active]: {
                    ...entityState,
                    section: action.section,
                },
            };
        case ENTITY_UPDATE_EXTRA:
            const newExtra = {
                ...entityState.extra,
                ...action.fields,
            };
            return {
                ...state,
                [active]: { ...entityState, extra: newExtra },
            };
        case ENTITY_DETAIL_SET_TABS:
            let newState = { ...state };
            if (action.deletedKey) delete newState[action.deletedKey];
            return {
                ...newState,
                active: action.active || state.active,
                tabs: action.tabs || state.tabs,
            };
        case CLEAR:
            return { ...initialState, tabs: {} };
        default:
            return state;
    }
}
