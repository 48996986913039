import React, { memo } from 'react';
import AbsoluteEmpty from 'components/AbsoluteEmpty';
import { Button } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

const AutomationsListEmptyView = memo(({ emptyViewProps }) => {
    return (
        <div className="fm-automation-settings__empty-view">
            <AbsoluteEmpty
                title={getLiteral('label_automations_empty_page')}
                subtitle={getLiteral('label_automations_empty_page_desc')}
                actions={[
                    <Button type="secondary" onClick={emptyViewProps.onCreate}>
                        {getLiteral('action_atomation_create')}
                    </Button>,
                    /*<Button type="secondary" onClick={() => {}}>
                        {getLiteral('action_see_templates')}
                    </Button>*/
                    ,
                ]}
            />
        </div>
    );
});

export default AutomationsListEmptyView;
