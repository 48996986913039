import { memo } from 'react';
import { Button } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';

import './styles.scss';

const AutomationBuilderFooter = memo(({ onSave, onClose, saveDisabled }) => {
    return (
        <div className="fm-automation-builder__footer">
            <Button onClick={onClose} type="terciary">
                {getLiteral('action_cancel')}
            </Button>
            <Button type="secondary" onClick={onSave} isDisabled={saveDisabled}>
                {getLiteral('action_save_automation')}
            </Button>
        </div>
    );
});

export default AutomationBuilderFooter;
