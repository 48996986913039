import { BILLBOARD, CAMPAIGNS, KPIS, PRODUCTS, SALESORDERS } from './constants/Entities';

import Login from 'containers/Login';
import Logout from 'containers/Logout';
import RecoverPassword from 'containers/RecoverPassword';
import ExpiredPassword from 'containers/ExpiredPassword';
import ChangePassword from 'containers/ChangePassword';
import ContractsValidation from 'containers/ContractsValidation';
import SignatureConfirm from 'containers/SignatureConfirm';
import ForceBIMobile from 'containers/DashboardForceBI/ForceBIMobile';

import Opportunities from 'containers/opportunities';
import Companies from 'containers/companies';
import Contacts from 'containers/contacts';
import Campaigns from 'containers/campaigns';
import SalesOrders from 'containers/SalesOrders';
import Documents from 'containers/documents';
import Billboard from 'containers/Billboard';
import Reports from 'containers/reports';
import Kpis from 'containers/kpis';
import Agenda from 'containers/agenda';
import Activities from 'containers/Activities';
import AcceptInvite from 'containers/AcceptInvite';
import OnboardingWizard from 'containers/OnboardingWizard';
import Auth from 'containers/Auth';
import Error from 'containers/Error';
import Placeholder from './containers/Placeholder';
import Metrics from 'containers/Metrics';
import Products from 'containers/Products';
import MetricsDashboardView from 'containers/Metrics/MetricsDashboardView';
import MetricsSFMView from 'containers/Metrics/MetricSFMView';
import Settings from 'containers/settings';
import CustomPage from 'containers/CustomPage';
import Dashboard from 'containers/Dashboard';

export const EXTERNAL_ROUTES = [
    {
        path: '/login',
        element: <Login />,
        noRedirectLogin: true,
    },
    {
        path: '/logout',
        element: <Logout />,
        noRedirectLogin: true,
    },
    {
        path: '/change-password',
        element: <ChangePassword />,
        noRedirectLogin: true,
    },
    {
        path: '/expired-password',
        element: <ExpiredPassword />,
        noRedirectLogin: true,
    },
    {
        path: '/recover-password',
        element: <RecoverPassword />,
        noRedirectLogin: true,
    },
    {
        path: '/signature-confirm',
        element: <SignatureConfirm />,
        noRedirectLogin: true,
    },
    {
        path: '/contracts-validation',
        element: <ContractsValidation />,
    },
    {
        path: '/accept-invite',
        element: <AcceptInvite />,
        noRedirectLogin: true,
    },
    {
        path: '/onboarding',
        element: <OnboardingWizard />,
        noRedirectLogin: true,
    },
    {
        path: '/error',
        element: <Error />,
        noRedirectLogin: true,
    },
    {
        path: '/error/:code',
        element: <Error />,
        noRedirectLogin: true,
    },
    {
        path: '/auth',
        element: <Auth />,
        noRedirectLogin: true,
    },
    {
        path: '/salesbi-mobile',
        element: <ForceBIMobile />,
        noRedirectLogin: true,
    },
];

export const ROUTES = [
    {
        title: 'page_title_accounts',
        path: '/companies/*',
        element: <Companies />,
    },
    {
        title: 'page_title_opportunities',
        path: '/opportunities/*',
        element: <Opportunities />,
    },
    {
        title: 'page_title_contacts',
        path: '/contacts/*',
        element: <Contacts />,
    },
    {
        title: 'page_title_campaigns',
        path: '/campaigns/*',
        element: <Campaigns />,
    },
    {
        title: 'page_title_salesorders',
        path: '/sales-orders/*',
        element: <SalesOrders />,
    },
    {
        title: 'page_title_documents',
        path: '/documents/*',
        element: <Documents />,
    },
    {
        title: 'page_title_news',
        path: '/news/*',
        element: <Billboard />,
    },
    {
        title: 'page_title_reports',
        path: '/reports/*',
        element: <Reports />,
    },
    {
        title: 'page_title_goals',
        path: '/kpis/*',
        element: <Kpis />,
    },
    {
        title: 'page_title_calendar',
        path: '/agenda/*',
        element: <Agenda />,
    },
    {
        title: 'page_title_activities',
        path: '/activities/*',
        element: <Activities />,
    },
    {
        title: 'page_title_activities',
        path: '/conversations/activities/*',
        element: <Activities />,
    },
    {
        title: 'label_dashboards',
        path: '/metrics/dashboards/view/:id',
        element: <MetricsDashboardView />,
    },
    {
        title: 'title_sales_force',
        path: '/metrics/sfm/:id',
        element: <MetricsSFMView />,
    },
    {
        title: 'label_metrics_and_reports',
        path: '/metrics/*',
        element: <Metrics />,
    },
    {
        title: 'page_title_products',
        path: '/products/*',
        element: <Products />,
    },
    {
        title: 'page_title_goals',
        path: '/try-kpis',
        element: <Placeholder entity={KPIS} />,
    },
    {
        title: 'page_title_salesorders',
        path: '/try-sales-orders',
        element: <Placeholder entity={SALESORDERS} />,
    },
    {
        title: 'page_title_products',
        path: '/try-products',
        element: <Placeholder entity={PRODUCTS} />,
    },
    {
        title: 'page_title_news',
        path: '/try-newsmanager',
        element: <Placeholder entity={BILLBOARD} />,
    },
    {
        title: 'page_title_campaigns',
        path: '/try-salescampaigns',
        element: <Placeholder entity={CAMPAIGNS} />,
    },
    {
        title: 'title_settings',
        path: '/settings/*',
        element: <Settings />,
    },
    {
        title: 'title_custom_page',
        path: '/custom-page',
        element: <CustomPage />,
    },
    {
        title: 'title_dashboard',
        path: '/dashboard',
        element: <Dashboard />,
    },
];
