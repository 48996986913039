import React, { memo, useMemo, useCallback } from 'react';
import { Select } from 'hoi-poi-ui';
import { isMobile } from 'utils/browser';

const SelectHoi = memo(({ usePlainValue, options, value, onChange, ...props }) => {
    const fValue = useMemo(
        () => (usePlainValue ? options?.find((option) => option.value === value) || value : value),
        [options, usePlainValue, value],
    );

    const onChangeValue = useCallback(
        (value) => onChange(usePlainValue ? value?.value : value, value),
        [onChange, usePlainValue],
    );

    return (
        <Select
            isSearchable={!isMobile}
            options={options}
            value={fValue}
            onChange={onChangeValue}
            {...props}
        />
    );
});

export default SelectHoi;
