import { Map, List } from 'immutable';
import { isEmpty } from 'utils/text';
import { randomId } from 'utils/numbers';
import { getLiteral } from 'utils/getLiteral';
import { successToast } from 'utils/toast';
import { SALESORDERS, SALESORDERSLINES } from 'constants/Entities';
import { SalesOrdersModel } from 'models';
import Context from 'managers/Context';
import { FuzzyMap } from 'utils/fuzzy';
import { isEmptyObject } from 'utils/objects';
import { getDifferentDay, formatDateForServer } from 'utils/dates';
import { getSrcProductCircleAvatar } from 'utils/getSrcAvatar';
import { getCurrency } from 'utils/currency';
import { getActiveCrud, getCustomizeCrudAction } from 'utils/crud';
import { getGroupByParentValue } from 'utils/getGroupByParentValue';
import { translateFollowingItemForBackend } from 'utils/filters';

function emptyFilterRate(item) {
    return !item.isDisabled;
}

export default class OrdersManager {
    canAsyncExtraFields() {
        return false;
    }

    getSchema = (defaultInputSchema) => {
        const state = Context.store.getState();
        const quoteProductByEnvironment = state?.config?.permission?.quoteProductByEnvironment;

        const schema = [
            {
                title: getLiteral('label_salesorder_details'),
                show: true,
                columns: 2,
                fields: [
                    {
                        ...defaultInputSchema,
                        id: 'idCompany',
                        serverId: 'idCompany',
                        fieldConfiguration: 'idEmpresa',
                        type: 'fuzzySearchSingle',
                        label: getLiteral('label_account'),
                        hint: getLiteral('action_searchcompany'),
                        inputAttrs: {
                            list: 'companyrates',
                            field: 'companyrates',
                            extraValueField: 'companyExtraInfo',
                            noFilter: true,
                            warningChangeModal: {
                                warningText: getLiteral(
                                    quoteProductByEnvironment
                                        ? 'warning_account_change_environment_orders'
                                        : 'warning_rate_change_salesorders',
                                ),
                                canShow: (data) =>
                                    quoteProductByEnvironment ||
                                    (state?.serverList?.ratesValueList?.data?.length &&
                                        data?.products?.length > 0),
                            },
                        },
                        column: 1,
                        hiddenForBulk: true,
                    },
                    {
                        ...defaultInputSchema,
                        id: 'idAddress',
                        serverId: 'idAddress',
                        fieldConfiguration: 'idCompanyAddress',
                        type: 'customField',
                        isCustom: true,
                        label: getLiteral('label_shipping_address_salesorders'),
                        column: 1,
                        hiddenForBulk: true,
                    },
                    {
                        ...defaultInputSchema,
                        id: 'idRate',
                        serverId: 'idRate',
                        fieldConfiguration: 'idTarifa',
                        type: 'singleValueList',
                        label: getLiteral('label_rate'),
                        fakeError: this.getRateCaducityError,
                        hasDefaultUserValues: true,
                        inputAttrs: {
                            list: 'ratesValueList',
                            hideIfEmptyList: true,
                            emptyFilter: emptyFilterRate,
                            beforeRenderValueList: this.filterTblRates,
                            withNoOptionValue: true, // show value even if not in the list
                            warningChangeModal: {
                                warningText: getLiteral('warning_rate_change_salesorders'),
                                canShow: (data) =>
                                    state?.serverList?.ratesValueList?.data?.length &&
                                    data?.products?.length > 0,
                            },
                        },
                        column: 1,
                        hiddenForBulk: true,
                    },
                    {
                        ...defaultInputSchema,
                        id: 'idCurrency',
                        type: 'singleValueList',
                        label: getLiteral('common_text_currency'),
                        column: 1,
                        inputAttrs: {
                            list: 'tblCurrency',
                            isClearable: false,
                        },
                        hiddenForBulk: true,
                    },
                    {
                        ...defaultInputSchema,
                        id: 'idContact',
                        serverId: 'idContact',
                        fieldConfiguration: 'idContacto',
                        type: 'fuzzySearchSingle',
                        label: getLiteral('label_contact'),
                        hint: getLiteral('action_search_contact'),
                        inputAttrs: {
                            ...FuzzyMap.contactos,
                            parentField: 'idEmpresa',
                            parentValueType: 'fuzzySearch',
                            readOnlyIfNoParent: true,
                            parentFieldForContext: 'idCompany',
                        },
                        column: 1,
                        hiddenForBulk: true,
                    },
                    {
                        ...defaultInputSchema,
                        id: 'idOpportunity',
                        serverId: 'idOpportunity',
                        fieldConfiguration: 'idExpediente',
                        type: 'fuzzySearchSingle',
                        label: getLiteral('title_opportunity'),
                        hint: getLiteral('action_searchopportunity'),
                        inputAttrs: {
                            groupFunction: getGroupByParentValue,
                            ...FuzzyMap.expedientes,
                            parentFieldNoRemove: true,
                            parentField: 'idEmpresa',
                            parentFieldForBackend: 'idinstalador',
                            parentFieldForContext: 'idCompany',
                            parentFieldForContext2: 'idContact',
                            mainGroupLabel: getLiteral('label_related_opportunities'),
                            otherGroupLabel: getLiteral('label_other_opportunities'),
                        },
                        column: 1,
                    },
                    {
                        ...defaultInputSchema,
                        id: 'reference',
                        serverId: 'reference',
                        fieldConfiguration: 'reference',
                        type: 'text',
                        label: getLiteral('label_reference_salesorders'),
                        column: 2,
                    },
                    {
                        ...defaultInputSchema,
                        id: 'expectedCloseDate',
                        serverId: 'expectedCloseDate',
                        fieldConfiguration: 'expectedCloseDate',
                        type: 'date',
                        label: getLiteral('label_valid_until'),
                        column: 2,
                        hiddenForBulk: true,
                    },
                    {
                        ...defaultInputSchema,
                        id: 'salesOrderType',
                        serverId: 'salesOrderType',
                        fieldConfiguration: 'salesOrderType',
                        type: 'singleValueList',
                        label: getLiteral('label_salesorder_type'),
                        hint: getLiteral('label_selectone'),
                        inputAttrs: {
                            list: 'tblTiposSalesOrders',
                            actions: getCustomizeCrudAction(
                                SALESORDERS,
                                '/settings/values-list',
                                `?entity=${SALESORDERS.trueName}&list=tblTiposSalesOrders`,
                            ),
                        },
                        column: 2,
                    },
                    {
                        ...defaultInputSchema,
                        id: 'idStateSalesOrder',
                        serverId: 'idStateSalesOrder',
                        fieldConfiguration: 'idEstadoSalesOrder',
                        type: 'singleValueList',
                        label: getLiteral('label_status_salesorders'),
                        hint: getLiteral('label_selectone'),
                        inputAttrs: {
                            list: 'tblEstadosSalesOrders',
                            actions: getCustomizeCrudAction(
                                SALESORDERS,
                                '/settings/values-list',
                                `?entity=${SALESORDERS.trueName}&list=tblEstadosSalesOrders`,
                            ),
                        },
                        column: 2,
                    },
                    {
                        ...defaultInputSchema,
                        id: 'idResponsible',
                        serverId: 'idResponsible',
                        fieldConfiguration: 'idComercial',
                        type: 'fuzzySearchSingle',
                        label: getLiteral('label_owner'),
                        hint: getLiteral('action_searchowner'),
                        hasDefaultUserValues: true,
                        inputAttrs: {
                            ...FuzzyMap.users,
                        },
                        column: 2,
                    },
                    {
                        ...defaultInputSchema,
                        id: 'idBranch',
                        serverId: 'idBranch',
                        fieldConfiguration: 'idSucursal',
                        type: 'singleValueList',
                        label: getLiteral('label_environment'),
                        hasDefaultUserValues: true,
                        inputAttrs: {
                            list: 'tblSucursales',
                        },
                        column: 2,
                        hiddenForBulk: true,
                    },
                ],
            },
        ];
        return schema;
    };

    afterGetSchema = ({ schema, dependencyMap, dynamicMap }) =>
        new Promise((resolve) => {
            const enableSalesOrdersTypes =
                Context.store.getState().config.userData.enableSalesOrdersTypes;

            schema.map((currElement, index) => {
                schema[index].fields = currElement.fields.filter((field) => {
                    switch (field.id) {
                        case 'salesOrderType':
                            if (!enableSalesOrdersTypes) return false;
                            else {
                                field.mandatory = true;
                                field.hidden = false;
                            }
                            return true;
                        default:
                            return true;
                    }
                });
            });

            resolve({
                schema,
                dependencyMap,
                dynamicMap,
            });
        });

    moreFieldsSchema = (defaultInputSchema, isCreate) =>
        new Promise((resolve) => {
            Context.extraFieldManager
                .getExtraFieldsCrudSchema(SALESORDERSLINES, defaultInputSchema)
                .then((result) => {
                    const { extraSchema } = Context.entityCrudManager.extraFieldsConfiguration(
                        result,
                        isCreate,
                    );

                    const extraFields = extraSchema.reduce((arr, tabs) => {
                        return [...arr, ...tabs.fields];
                    }, []);

                    let standardSchema = [
                        {
                            ...defaultInputSchema,
                            id: 'entityId',
                            serverId: 'entityId',
                            fieldConfiguration: 'entityId',
                        },
                        {
                            ...defaultInputSchema,
                            id: 'idProduct',
                            serverId: 'idProduct',
                            fieldConfiguration: 'idProduct',
                        },
                        {
                            ...defaultInputSchema,
                            id: 'quantity',
                            serverId: 'quantity',
                            fieldConfiguration: 'quantity',
                        },
                        {
                            ...defaultInputSchema,
                            id: 'price',
                            serverId: 'price',
                            fieldConfiguration: 'price',
                        },
                        {
                            ...defaultInputSchema,
                            id: 'discount1',
                            serverId: 'discount1',
                            fieldConfiguration: 'discount1',
                        },
                        {
                            ...defaultInputSchema,
                            id: 'discount2',
                            serverId: 'discount2',
                            fieldConfiguration: 'discount2',
                        },
                        {
                            ...defaultInputSchema,
                            id: 'discount3',
                            serverId: 'discount3',
                            fieldConfiguration: 'discount3',
                        },
                        {
                            ...defaultInputSchema,
                            id: 'discount4',
                            serverId: 'discount4',
                            fieldConfiguration: 'discount4',
                        },
                        {
                            ...defaultInputSchema,
                            id: 'finalPrice',
                            serverId: 'finalPrice',
                            fieldConfiguration: 'finalPrice',
                        },
                        {
                            ...defaultInputSchema,
                            id: 'description',
                            serverId: 'description',
                            fieldConfiguration: 'description',
                        },
                    ];

                    standardSchema =
                        Context.entityCrudManager.standardFieldsConfiguration(
                            this,
                            [{ fields: standardSchema }],
                            SALESORDERSLINES,
                            isCreate,
                        )?.[0]?.fields || [];

                    const schema = [
                        {
                            show: true,
                            fields: [
                                {
                                    ...defaultInputSchema,
                                    id: 'products',
                                    serverId: 'products',
                                    fieldConfiguration: 'products',
                                    type: 'customField',
                                    isCustom: true,
                                    schema: [...standardSchema, ...extraFields],
                                    hiddenForBulk: true,
                                },
                            ],
                        },
                        {
                            show: true,
                            fields: [
                                {
                                    ...defaultInputSchema,
                                    id: 'internalComments',
                                    serverId: 'internalComments',
                                    fieldConfiguration: 'internalComments',
                                    type: 'textarea',
                                    label: getLiteral('label_internalcomment'),
                                    description: getLiteral('helptext_sales_team_comments'),
                                    inputAttrs: {
                                        isHighlighted: false,
                                        rows: 1,
                                        rowsMax: 10,
                                        withoutResizer: true,
                                    },
                                    hiddenForBulk: true,
                                },
                            ],
                        },
                        {
                            show: true,
                            fields: [
                                {
                                    ...defaultInputSchema,
                                    id: 'comments',
                                    serverId: 'comments',
                                    fieldConfiguration: 'comments',
                                    type: 'textarea',
                                    label: getLiteral('label_comment_salesorders'),
                                    inputAttrs: {
                                        isHighlighted: true,
                                        rows: 1,
                                        rowsMax: 10,
                                        withoutResizer: true,
                                    },
                                    hiddenForBulk: true,
                                },
                            ],
                        },
                    ];

                    resolve(schema);
                })
                .catch((error) => {
                    console.error('error getting extrafields schema for salesorderslines:', error);
                    resolve([]);
                });
        });

    beforeCrud() {
        return new Promise((resolve) => {
            Promise.all([this.getTblRates()])
                .then(([rates]) => {
                    resolve({ extraData: { rates } });
                })
                .catch((error) => {
                    console.error(error);
                });
        });
    }

    beforeRenderValueList(options) {
        const config = Context.store.getState().config;
        const { currencySymbol, locale } = config.userData;

        const finalOptions = options.map((option) => {
            const { label, value, ExtraInfo: description, ExtraInfoId: price } = option;
            const subLabel = [];

            if (description) subLabel.push(description);
            if (price) {
                subLabel.push(
                    getCurrency({
                        number: price,
                        currencySymbol,
                        precision: 2,
                        locale,
                    }),
                );
            }

            return {
                label,
                value,
                subLabel,
            };
        });

        return finalOptions;
    }

    getSpecificEntityDefaultValues(data, id) {
        if (!data) return;
        const config = Context.store.getState().config;

        if (!id) {
            // creation only
            if (!data.idResponsible || isEmptyObject(data.idResponsible)) {
                data.idResponsible = {
                    label: `${config.userData.nombre} ${config.userData.apellidos}`,
                    value: config.userData.idUsuario,
                };
            }
            const userRate = parseInt(config.userData.idTarifa, 10) || -1;
            const userCurrency = config.userData.currencyISO || '';
            const rate =
                data?.extraData?.rates &&
                userRate &&
                data.extraData.rates.find((rate) => rate.value === userRate);

            if (rate) data.idRate = rate.value;
            if (rate?.currency || userCurrency) {
                data.idCurrency = rate?.idCurrency;
                data.currency = rate?.currency;
                data.currencySymbol = rate?.currencySymbol;
            }

            if (!data.idCurrency && config.userData.currencyId) {
                data.currency = config.userData.currencyISO || '';
                data.currencySymbol = config.userData.currencySymbol;
                data.idCurrency = parseInt(config.userData.currencyId, 10);
            }

            if (!data.idBranch || isEmptyObject(data.idBranch)) {
                data.idBranch = config.userData.idEntorno;
            }

            // Ask backend why they send different key on defaultValues and standardFieldsSchema
            // for idStateSalesOrder field default value configuration
            if (!data.idStateSalesOrder) {
                let defaultValueFields = config.defaultvaluefields.entity.find(
                    (e) => e.name === SALESORDERS.customField,
                );
                if (defaultValueFields) {
                    defaultValueFields = defaultValueFields.defaultvaluefield;
                    defaultValueFields = defaultValueFields.reduce((obj, d) => {
                        obj[d.field.toLowerCase()] = d;
                        return obj;
                    }, {});
                    if (defaultValueFields.idstatesalesorder) {
                        data.idStateSalesOrder = defaultValueFields.idstatesalesorder.value;
                    }
                }
            }
        }
        return data;
    }

    getTblRates() {
        return new Promise((resolve) => {
            Context.serverListManager
                .getList('ratesValueList')
                .then((result) => {
                    resolve(this.filterTblRates(result));
                })
                .catch((error) => {
                    console.error(error);
                });
        });
    }

    filterTblRates(options) {
        if (!options) return options;
        return options.filter((option) => {
            const { startDate, endDate } = option;
            if (startDate === null && endDate === null) return true;
            if (endDate === '01/01/1900 00:00:00') return true;
            const startDateNum = getDifferentDay(startDate, 'DD/MM/YYYY HH:mm:ss');
            const endDateNum = getDifferentDay(endDate, 'DD/MM/YYYY HH:mm:ss');
            return startDateNum <= 0 && endDateNum >= 0;
        });
    }

    changeFields(fields) {
        const state = Context.store.getState();

        const locale = state?.config?.userData?.locale || undefined;
        const quoteProductByEnvironment = state?.config?.permission?.quoteProductByEnvironment;
        const activeCrud = getActiveCrud(state);
        const dataCrud = activeCrud?.data;
        const mappedSchema = activeCrud?.mappedSchema;
        const config = state.config;
        let newFields = { ...fields };

        if (newFields.hasOwnProperty('idRate')) {
            if (!newFields.idRate) {
                // default value for currency
                newFields.currency = config.userData.currencyISO || '';
                newFields.currencySymbol = config.userData.currencySymbol || '';
                newFields.idCurrency = parseInt(config.userData.currencyId, 10) || '';
            } else {
                const newRate = dataCrud?.extraData?.rates?.find(
                    (rate) =>
                        Number(rate.value) === Number(newFields.idRate?.value || newFields.idRate),
                );
                newFields.currency = newRate?.currency || config.userData.currencyISO || '';
                newFields.currencySymbol =
                    newRate?.currencySymbol || config.userData.currencySymbol || '';
                newFields.idCurrency =
                    newRate?.idCurrency || parseInt(config.userData.currencyId, 10) || '';
            }
            newFields.products =
                dataCrud?.products?.map((current) => {
                    current.currencySymbol = newFields.currencySymbol;
                    return current;
                }) || [];
        }

        if (newFields.hasOwnProperty('idCompany')) {
            const extraFieldId = mappedSchema.idCompany.inputAttrs.extraValueField;
            if (!newFields.idCompany) {
                // Address changes
                newFields.idAddress = '';
                newFields.address = '';
                newFields[extraFieldId] = null;

                // Rate changes
                newFields.idRate = config.userData.idTarifa;
                newFields.currency = config.userData.currencyISO || '';
                newFields.currencySymbol = config.userData.currencySymbol || '';
                newFields.idCurrency = parseInt(config.userData.currencyId, 10) || '';

                if (quoteProductByEnvironment) {
                    newFields.idBranch = null;
                }
            } else {
                // Address changes
                newFields[extraFieldId] = newFields.idCompany.ExtraInfo4;
                if (newFields.idCompany.ExtraInfo2) {
                    newFields.idAddress = -1;
                    newFields.address = newFields.idCompany.ExtraInfo2?.trim();
                    newFields.withCompanyAddress = true;
                } else {
                    newFields.idAddress = '';
                }

                // Rate changes
                if (newFields.idCompany.ExtraInfo) {
                    newFields.idRate = {
                        label: newFields.idCompany.ExtraInfo,
                        value: newFields.idCompany.ExtraInfoId,
                    };

                    const newRate = dataCrud?.extraData?.rates?.find(
                        (rate) => Number(rate.value) === Number(newFields.idRate.value),
                    );

                    newFields.currency = newRate?.currency || config.userData.currencyISO || '';
                    newFields.currencySymbol =
                        newRate?.currencySymbol || config.userData.currencySymbol || '';
                    newFields.idCurrency =
                        newRate?.idCurrency || parseInt(config.userData.currencyId, 10) || '';
                }

                if (quoteProductByEnvironment) {
                    newFields.idBranch = newFields.idCompany.ExtraInfoId7;
                }
            }

            if (quoteProductByEnvironment) {
                newFields.products = [];
                newFields.subtotal = getCurrency({
                    number: 0,
                    currencySymbol: dataCrud.currencySymbol,
                    precision: 2,
                    locale,
                });
                newFields.total = getCurrency({
                    number: 0,
                    currencySymbol: dataCrud.currencySymbol,
                    precision: 2,
                    locale,
                });
                newFields.subtotalRaw = 0;
                newFields.totalRaw = 0;
            } else {
                // updating products currencySymbol
                newFields.products =
                    dataCrud?.products?.map((current) => {
                        current.currencySymbol = newFields.currencySymbol;
                        return current;
                    }) || [];
            }
        }

        if (
            newFields.hasOwnProperty('idAddress') &&
            !!newFields.idAddress &&
            typeof newFields.idAddress === 'object'
        ) {
            newFields.address = newFields.idAddress.address;
            newFields.address2 = newFields.idAddress.address2;
            newFields.city = newFields.idAddress.city;
            newFields.country = newFields.idAddress.country;
            newFields.idCountry = newFields.idAddress.idCountry;
            newFields.postCode = newFields.idAddress.postCode;
            newFields.province = newFields.idAddress.province;
            newFields.idAddress = newFields.idAddress.idAddress;
            newFields.withCompanyAddress = false;
        }

        if (
            newFields.hasOwnProperty('discount1') ||
            newFields.hasOwnProperty('discount2') ||
            newFields.hasOwnProperty('discount3') ||
            newFields.hasOwnProperty('discount4')
        ) {
            let discount1 = dataCrud.discount1 || null;
            let discount2 = dataCrud.discount2 || null;
            let discount3 = dataCrud.discount3 || null;
            let discount4 = dataCrud.discount4 || null;
            if (newFields.hasOwnProperty('discount1')) discount1 = newFields.discount1;
            if (newFields.hasOwnProperty('discount2')) discount2 = newFields.discount2;
            if (newFields.hasOwnProperty('discount3')) discount3 = newFields.discount3;
            if (newFields.hasOwnProperty('discount4')) discount4 = newFields.discount4;

            const { subtotal, total } = this.calculateEntityProducts({
                products: dataCrud.products,
                discount1,
                discount2,
                discount3,
                discount4,
                currencySymbol: dataCrud.currencySymbol,
            });

            newFields.subtotalRaw = subtotal;
            newFields.subtotal = getCurrency({
                number: subtotal,
                currencySymbol: dataCrud.currencySymbol,
                precision: 2,
                locale,
            });
            newFields.totalRaw = total;
            newFields.total = getCurrency({
                number: total,
                currencySymbol: dataCrud.currencySymbol,
                precision: 2,
                locale,
            });
        }

        if (newFields.hasOwnProperty('product')) {
            let defaultValueFields = mappedSchema?.products?.schema?.reduce(
                (obj, field) =>
                    Context.entityCrudManager.getDbDefaultValuesField(obj, {
                        ...field,
                        id: field.id.toLowerCase(),
                    }),
                {},
            );

            const currentProducts = dataCrud.products || [];
            let crudProducts = [];
            const changeInfo = newFields.product;

            if (changeInfo.rowIndex || typeof changeInfo.rowIndex === 'number') {
                currentProducts[changeInfo.rowIndex][changeInfo.field] = changeInfo.value;
                const discount = this.calculateTotalDiscount(
                    currentProducts[changeInfo.rowIndex].discount1,
                    currentProducts[changeInfo.rowIndex].discount2,
                    currentProducts[changeInfo.rowIndex].discount3,
                    currentProducts[changeInfo.rowIndex].discount4,
                );
                currentProducts[changeInfo.rowIndex].finalprice =
                    currentProducts[changeInfo.rowIndex].quantity *
                    currentProducts[changeInfo.rowIndex].price *
                    discount;
            } else {
                const modalProducts = Array.isArray(newFields.product)
                    ? newFields.product
                    : [newFields.product];

                crudProducts = modalProducts
                    .filter((newFieldProduct) => newFieldProduct.product)
                    .map((newFieldProduct) => {
                        const modalProduct = newFieldProduct.product;
                        const fieldQuantity = newFieldProduct.quantity;
                        const recommendedQuantity = modalProduct.hasOwnProperty(
                            'recommendedQuantity',
                        )
                            ? modalProduct.recommendedQuantity
                            : -1;

                        let crudProduct = {
                            ...defaultValueFields,
                            id: modalProduct.id + `/${randomId()}`,
                            isNew: true,
                            idproduct: modalProduct.id,
                            model: modalProduct.name,
                            quantity:
                                fieldQuantity || fieldQuantity === 0
                                    ? fieldQuantity
                                    : recommendedQuantity,
                            price:
                                modalProduct.finalprice ||
                                (defaultValueFields.price
                                    ? parseInt(defaultValueFields.price, 10)
                                    : 0),
                            discount1: modalProduct.discount1,
                            discount2: modalProduct.discount2,
                            discount3: modalProduct.discount3,
                            discount4: modalProduct.discount4,
                            idThumbnail: modalProduct.idThumbnail,
                            description: modalProduct.description,
                            imageList: modalProduct.imageList,
                        };

                        if (defaultValueFields) {
                            // parsing default discounts for products in SalesOrders
                            for (let i = 1; i <= 4; i++) {
                                if (
                                    !crudProduct[`discount${i}`] &&
                                    crudProduct[`discount${i}`] !== 0 &&
                                    defaultValueFields[`discount${i}`]
                                ) {
                                    crudProduct[`discount${i}`] = parseInt(
                                        defaultValueFields[`discount${i}`],
                                        10,
                                    );
                                }
                            }
                        }

                        const discount = this.calculateTotalDiscount(
                            crudProduct.discount1,
                            crudProduct.discount2,
                            crudProduct.discount3,
                            crudProduct.discount4,
                        );

                        crudProduct.finalprice =
                            crudProduct.quantity * crudProduct.price * discount;
                        return crudProduct;
                    });
            }

            const newProducts = [...currentProducts, ...crudProducts];
            delete newFields.product;
            const { products, subtotal, total } = this.calculateEntityProducts({
                products: newProducts,
                discount1: dataCrud.discount1,
                discount2: dataCrud.discount2,
                discount3: dataCrud.discount3,
                discount4: dataCrud.discount4,
                currencySymbol: dataCrud.currencySymbol,
            });
            newFields.products = products;
            newFields.subtotal = getCurrency({
                number: subtotal,
                currencySymbol: dataCrud.currencySymbol,
                precision: 2,
                locale,
            });
            newFields.total = getCurrency({
                number: total,
                currencySymbol: dataCrud.currencySymbol,
                precision: 2,
                locale,
            });
            newFields.subtotalRaw = subtotal;
            newFields.totalRaw = total;
        }

        if (newFields.hasOwnProperty('removeProducts')) {
            const currentProducts = dataCrud.products || [];
            const finalProducts = currentProducts.reduce((arr, current) => {
                if (newFields.removeProducts.includes(current.id.toString())) return arr;
                arr.push(current);
                return arr;
            }, []);
            const { products, subtotal, total } = this.calculateEntityProducts({
                products: finalProducts,
                discount1: dataCrud.discount1,
                discount2: dataCrud.discount2,
                discount3: dataCrud.discount3,
                discount4: dataCrud.discount4,
                currencySymbol: dataCrud.currencySymbol,
            });
            newFields.products = products;
            newFields.subtotal = getCurrency({
                number: subtotal,
                currencySymbol: dataCrud.currencySymbol,
                precision: 2,
                locale,
            });
            newFields.total = getCurrency({
                number: total,
                currencySymbol: dataCrud.currencySymbol,
                precision: 2,
                locale,
            });
            newFields.subtotalRaw = subtotal;
            newFields.totalRaw = total;
        }

        if (newFields.hasOwnProperty('orderedProducts')) {
            newFields.products = newFields.orderedProducts;
        }

        return newFields;
    }

    postChangeFields({ fields, data, dispatchChanges, state }) {
        if (!data.products?.length) return;

        const productIds = data.products.map((product) => product.idproduct || product.id);
        const locale = state?.config?.userData?.locale || undefined;
        let promise;

        // Changing company and auto-assigned rate
        if (fields.hasOwnProperty('idCompany') && fields.hasOwnProperty('idRate')) {
            promise = this.context.domainManager.getSalesOrderProductsByIds(
                fields.idRate?.value || fields.idRate || -1,
                fields.idCompany?.value || fields.idCompany || -1,
                productIds,
            );
        }

        // Changing only company without rate
        if (fields.hasOwnProperty('idCompany') && !fields.hasOwnProperty('idRate')) {
            promise = this.context.domainManager.getSalesOrderProductsByIds(
                data.idRate?.value || data.idRate || -1,
                fields.idCompany?.value || fields.idCompany || -1,
                productIds,
            );
        }

        // Changing only rate
        if (!fields.hasOwnProperty('idCompany') && fields.hasOwnProperty('idRate')) {
            promise = this.context.domainManager.getSalesOrderProductsByIds(
                fields.idRate?.value || fields.idRate || -1,
                data.idCompany?.value || fields.idCompany || -1,
                productIds,
            );
        }

        const quoteProductByEnvironment = state?.config?.permission?.quoteProductByEnvironment;
        if (!promise || quoteProductByEnvironment) return;
        promise
            .then((result) => {
                if (!result?.Result?.length) return;
                const state = Context.store.getState();
                const config = state.config;
                let defaultValueFields = [];
                if (config.defaultvaluefields.entity) {
                    defaultValueFields = config.defaultvaluefields.entity.find(
                        (e) => e.name === SALESORDERSLINES.customField,
                    );
                    if (defaultValueFields) {
                        defaultValueFields = defaultValueFields.defaultvaluefield;
                        defaultValueFields = defaultValueFields.reduce((obj, d) => {
                            obj[d.field.toLowerCase()] = d;
                            return obj;
                        }, {});
                    }
                }

                fields.products = data.products.map((product) => {
                    const updatedProduct = result.Result.find(
                        (item) => item.id === product.idproduct,
                    );
                    product.price = updatedProduct.finalprice;
                    product.discount1 =
                        updatedProduct.discount1 || defaultValueFields.discount1?.value;
                    product.discount2 =
                        updatedProduct.discount2 || defaultValueFields.discount2?.value;
                    product.discount3 =
                        updatedProduct.discount3 || defaultValueFields.discount3?.value;
                    product.discount4 =
                        updatedProduct.discount4 || defaultValueFields.discount4?.value;
                    const totalDiscount = this.calculateTotalDiscount(
                        product.discount1,
                        product.discount2,
                        product.discount3,
                        product.discount4,
                    );
                    product.finalprice =
                        product.quantity * updatedProduct.finalprice * totalDiscount;
                    return product;
                });

                // Recalculating totals
                const { subtotal, total } = this.calculateEntityProducts({
                    products: fields.products,
                    discount1: data.discount1,
                    discount2: data.discount2,
                    discount3: data.discount3,
                    discount4: data.discount4,
                    currencySymbol: data.currencySymbol,
                });
                fields.subtotalRaw = subtotal;
                fields.totalRaw = total;
                fields.subtotal = getCurrency({
                    number: subtotal,
                    currencySymbol: data.currencySymbol,
                    precision: 2,
                    locale,
                });
                fields.total = getCurrency({
                    number: total,
                    currencySymbol: data.currencySymbol,
                    precision: 2,
                    locale,
                });
                dispatchChanges(fields);
            })
            .catch(console.error);
    }

    calculateTotalDiscount(discount1, discount2, discount3, discount4) {
        // calculate total discount like a boss as the formula of SalesOrdersModel
        // see get total() from SalesOrdersModel to get more information
        let totalDiscount = 1;
        if (discount1) totalDiscount *= 1 - discount1 / 100;
        if (discount2) totalDiscount *= 1 - discount2 / 100;
        if (discount3) totalDiscount *= 1 - discount3 / 100;
        if (discount4) totalDiscount *= 1 - discount4 / 100;
        totalDiscount = 1 - (1 - totalDiscount);
        return totalDiscount;
    }

    getProductsSchema = (schema) => {
        return schema.find((item) => item?.fields?.[0]?.id === 'products')?.fields?.[0]?.schema;
    };

    checkCustomErrors = (errors, schema, data) => {
        //Check productLines errors
        const productsSchema = this.getProductsSchema(schema);
        const productsData = data.products || [];
        let newErrors = {};
        let tableErrors = {};
        let firstErrorField = '';
        let customErrors = {
            ...errors,
            ...newErrors,
        };

        productsData.forEach((product, rowIndex) => {
            productsSchema.forEach((current) => {
                const noValue = !product[current.id] && !product[current.id.toLowerCase()];
                const noValueNumber =
                    typeof product[current.id] !== 'number' &&
                    typeof product[current.id.toLowerCase()] !== 'number';

                if (current.mandatory && noValue && noValueNumber) {
                    newErrors[current.id] = 'mandatory';
                    if (!tableErrors[current.id]) tableErrors[current.id] = { rows: {} };
                    tableErrors[current.id].rows[rowIndex] = {
                        error: 'mandatory',
                        colId: current.id,
                    };
                    if (isEmptyObject(errors) && !firstErrorField) firstErrorField = current.id;
                }
            });
        });

        if (firstErrorField) {
            customErrors = {
                ...customErrors,
                firstErrorField,
                products: tableErrors,
            };
        }

        return customErrors;
    };

    beforeSave(schema, data) {
        if (!data) return data;

        data.extraFields = {};
        schema.forEach((tab, index) => {
            if (index === 0) return; // first tab is standard fields tab
            tab.fields?.forEach((field) => {
                if (['products', 'internalComments', 'comments'].includes(field.id)) return;
                if (data.hasOwnProperty(field.serverId)) {
                    data.extraFields[field.serverId] = data[field.serverId];
                    delete data[field.serverId];
                }
            });
        });

        if (data.products?.length > 0) {
            const productsSchema = this.getProductsSchema(schema);
            data.orderslines = data.products.map((product) => {
                let crudProduct = {
                    idSalesOrder: data.id,
                    extraFields: {},
                };

                // new line so id is the product id instead of this line id
                if (!product.isNew) {
                    crudProduct.entityId = product.id;
                }

                productsSchema.forEach((field) => {
                    const id = product.hasOwnProperty(field.serverId)
                        ? field.serverId
                        : field.serverId.toLowerCase();

                    if (field.isExtra) {
                        switch (field.type) {
                            case 'date':
                            case 'dateTime':
                                if (product[id])
                                    crudProduct.extraFields[id] = formatDateForServer(
                                        product[id],
                                        'L',
                                    );
                                break;
                            default:
                                crudProduct.extraFields[id] = product[id];
                                break;
                        }

                        return;
                    } else {
                        crudProduct[id] = product[id];
                    }
                });

                // backend case insensitive...
                delete crudProduct.entityid;

                return crudProduct;
            });
            delete data.products;
        }

        data.amountSalesOrder = data.totalRaw;
        delete data.totalRaw;
        delete data.subtotalRaw;
        delete data.rawProducts;
        delete data.extraData;
        delete data.currency;
        delete data.currencySymbol;
        delete data.address;
        delete data.companyExtraInfo;

        return data;
    }

    getEntity(id, success, error, withExtraFields = true) {
        const entity = new Promise((resolve, reject) => {
            this.getSalesOrder(id, resolve, reject, withExtraFields);
        });

        const entityProducts = new Promise((resolve, reject) => {
            this.getOrderProducts(id, resolve, reject);
        });

        Promise.all([entity, entityProducts]).then((result) => {
            const { entity, data } = result[0];
            if (!data) {
                error('the entity does not exists');
                return;
            }
            entity.products = result[1];
            data.products = result[1];
            success(entity, data);
        }, error);
    }

    getSalesOrder(id, success, error, withExtraFields = true) {
        this.context.domainManager.getEntity(
            id,
            SALESORDERS.entity,
            withExtraFields,
            (data) => {
                const entity = { ...data };
                this.context.extraFieldManager.getExtraFieldSchema(
                    SALESORDERS,
                    (extraFieldSchema) => {
                        const extraFields = [];
                        extraFieldSchema.forEach((section) => {
                            const wrapperSection = {
                                descripcion: section.descripcion,
                                id: section['-id'],
                                data: [],
                            };
                            section.tabFields.forEach((field) => {
                                field.descripcion = field.description;
                                let value = entity[field.id.toLowerCase()];
                                switch (field.dataType) {
                                    case 'text':
                                    case 'decimal':
                                    case 'percent':
                                    case 'bool':
                                    case 'currency':
                                    case 'integer':
                                    case 'date':
                                        field.value =
                                            value?.value !== undefined ? value.value : value;
                                        break;
                                    case 'relatedValueList':
                                    case 'singleValueList':
                                    case 'fuzzySearchSingle':
                                    case 'multipleValueList':
                                    case 'fuzzySearchMultiple':
                                        field.value = value?.value;
                                        field.label = value?.description;
                                        break;
                                }

                                wrapperSection.data.push(field);
                            });
                            extraFields.push(wrapperSection);
                        });
                        entity.extraFields = extraFields;
                        success({ entity, data });
                    },
                    error,
                );
            },
            error,
        );
    }

    getOrderProducts(id, success, error) {
        this.context.domainManager.getOrderProducts(id, (data) => success(data.Result), error);
    }

    getSalesOrdersProductLines(idFolder, searchText, idRate, idCompany, idEntorno) {
        return new Promise((resolve, reject) => {
            this.context.domainManager.getSalesOrderProducts(
                0,
                500,
                idFolder,
                searchText,
                idRate?.value || idRate,
                [],
                idCompany?.value || idCompany,
                idEntorno,
                (result) => {
                    const groups = result?.Result?.reduce((groups, product) => {
                        if (!groups[product.idFolder]) {
                            groups[product.idFolder] = {
                                id: product.idFolder,
                                pathId: product.pathId,
                                path: product.path,
                                products: [],
                            };
                        }
                        groups[product.idFolder].products.push(product);
                        return groups;
                    }, {});
                    resolve(
                        Object.keys(groups)
                            .map((key) => groups[key])
                            .sort((a, b) => Number(a.id) - Number(b.id)),
                    );
                },
                reject,
            );
        });
    }

    getSalesOrderProducts(idFolder, searchText, idRate, idCompany, idEntorno) {
        return new Promise((resolve, reject) => {
            this.context.domainManager.getSalesOrderProducts(
                0,
                500,
                idFolder,
                searchText,
                idRate?.value || idRate,
                [],
                idCompany?.value || idCompany,
                idEntorno,
                (result) => {
                    resolve(result.Result);
                },
                reject,
            );
        });
    }

    calculateEntityProducts(data, mode) {
        let subtotal = 0;
        const rawProducts = data.products ? [...data.products] : [];
        const products =
            data.products?.map((product, index) => {
                subtotal += product.finalprice || 0;
                return this.parseProduct(product, index, data.currencySymbol, mode);
            }) || [];

        const discount = this.calculateTotalDiscount(
            data.discount1,
            data.discount2,
            data.discount3,
            data.discount4,
        );
        const total = subtotal * discount;

        return { rawProducts, products, subtotal, total };
    }

    parseProduct(product, index, currencySymbol, mode) {
        const firstImageId = product.imageList ? product.imageList.split(';')[0] : null;
        const productImageUrl = getSrcProductCircleAvatar(firstImageId);

        const imageListUrls =
            product.imageList?.split(';').reduce((arr, id) => {
                const { src, fallbackSrc } = getSrcProductCircleAvatar(id);
                arr.push({ src, fallbackSrc });
                return arr;
            }, []) || null;

        const flattenExtraFields = Object.keys(product).reduce((newObj, key) => {
            const field = product[key];
            if (field?.hasOwnProperty('value') && field?.hasOwnProperty('description')) {
                if (mode === 'detail') newObj[key] = field.description;
                else if (mode === 'edit')
                    newObj[key] = { label: field.description, value: String(field.value) };
            } else if (field?.hasOwnProperty('value')) {
                newObj[key] = field.value;
            } else newObj[key] = field;

            return newObj;
        }, {});

        return {
            rawData: product,
            ...flattenExtraFields,
            numLine: index + 1,
            currencySymbol: currencySymbol,
            rowImageUrl: productImageUrl.src,
            rowImageUrlFallback: productImageUrl.fallbackSrc,
            imageListUrls,
            discount1: product.discount1 || 0,
            discount2: product.discount2 || 0,
            discount3: product.discount3 || 0,
            discount4: product.discount4 || 0,
        };
    }

    getExtraClassNameStateValue(option) {
        if (option.win === 'True' && option.lost === '') {
            return 'salesorders-state-win';
        }
        if (option.win === 'False' && option.lost === 'True') {
            return 'salesorders-state-lost';
        }
    }

    updateEntity(id, entity, success, error) {
        const config = this.context.cacheManager.getConfigStore();
        this.getCrudSchema(
            config,
            SALESORDERS,
            (schema) => {
                const crud = SalesOrdersModel.fromCrud(entity);
                this.context.domainManager.updateEntity(
                    SALESORDERS.entity,
                    id,
                    crud.createCrud(schema),
                    (response) => {
                        successToast({
                            text: getLiteral('success_salesorders_entity_updated'),
                        });
                        success(response);
                    },
                    error,
                );
            },
            error,
        );
    }

    createEntity(entity, success, error) {
        const config = this.context.cacheManager.getConfigStore();
        this.getCrudSchema(
            config,
            SALESORDERS,
            (schema) => {
                const crud = SalesOrdersModel.fromCrud(entity);
                this.context.domainManager.createEntity(
                    SALESORDERS.entity,
                    crud.createCrud(schema),
                    (response) => {
                        successToast({
                            text: getLiteral('success_salesorders_entity_created'),
                        });
                        success(response);
                    },
                    error,
                );
            },
            error,
        );
    }

    refreshData(isDeleted, id) {
        if (isDeleted) {
            successToast({
                text: getLiteral('success_salesorders_entity_deleted'),
            });
            this.context.store.dispatch(this.context.actions.OrdersActions.refreshDataDelete(id));
        } else {
            this.context.store.dispatch(this.context.actions.OrdersActions.refreshData());
        }
    }

    getOrdersTypes(success, error) {
        this.context.valueListManager.getValueList('tblEstadosSalesOrders', success, error);
    }

    getOrdersGrid(init, count, filter, success, error) {
        this.getOrdersTypes(
            (ordersTypes) => {
                let grid = this.context.store.getState().orders.grid;
                const data = grid.get('data');
                if (data.size === 0) {
                    grid = groupByState(ordersTypes, []);
                    success(grid.data, 0);
                }

                this.context.domainManager.getOrdersGrid(
                    init,
                    count,
                    filter,
                    (response) => {
                        if (init === 0) {
                            grid = groupByState(ordersTypes, response);
                        } else {
                            grid = addOrderToState(grid, init, response);
                        }
                        success(grid.data, grid.max);
                    },
                    error,
                );
            },
            (e) => {
                console.error(e);
                error('empty types');
            },
        );
    }

    getOrders(init, count, filter, success, error) {
        const { list } = this.context.store.getState().orders;
        const sortField = list.get('orderField');
        const sortDir = list.get('orderDir');
        this.context.domainManager.getOrders(
            init,
            count,
            filter,
            sortField,
            sortDir,
            (data) => {
                success(data.Result ? data.Result : List(), data.TotalRows, init);
            },
            error,
        );
    }

    getSchemaExtraField(success, error) {
        this.context.extraFieldManager.getExtraFields(
            SALESORDERS,
            (schema) => {
                let resultSchema = Map();
                schema.forEach((value) => {
                    resultSchema = resultSchema.set(value.id, value);
                });
                success(resultSchema);
            },
            error,
        );
    }

    setFollow(orderId, follow, isShared = false, success, error) {
        this.context.domainManager.setFollow(
            SALESORDERS.entity,
            orderId,
            follow,
            isShared,
            () => {
                const orders = this.context.store.getState().orders;
                let { list, grid } = orders;
                const index = list.get('data').findIndex((item) => item.get('id') === orderId);

                if (orders.filters.get('followingItem')) {
                    const newlist = list.get('data').delete(index);
                    list = list.update('data', () => newlist);
                    list = list.update('total', (total) => total - 1);
                } else {
                    const newlist = list
                        .get('data')
                        .updateIn([index, 'followingItem'], () => follow);
                    list = list.update('data', () => newlist);
                }

                const auxgrid = grid.get('data').map((group) => {
                    const gridindex = group.data.findIndex((item) =>
                        item ? item.get('id') === orderId : false,
                    );
                    if (gridindex !== -1) {
                        if (orders.filters.get('followingItem')) {
                            group.data = group.data.delete(gridindex);
                        } else {
                            group.data = group.data.updateIn(
                                [gridindex, 'followingItem'],
                                () => follow,
                            );
                        }
                    }
                    return group;
                });

                let { tabs, select } = this.context.store.getState().detail;
                if (
                    select !== -1 &&
                    tabs.getIn([select, 'entityObject', 'entity']).id === orderId
                ) {
                    tabs = tabs.updateIn([select, 'entityObject', 'entity'], (entity) => {
                        entity.followingItem = follow;
                        return Object.assign({}, entity);
                    });
                }

                this.context.store.dispatch(this.context.actions.DetailActions.updateTabs(tabs));
                success(list, auxgrid);
            },
            error,
        );
    }

    getErrors(schema, entityCrud, dynamic) {
        const errorsObject = this.context.crudManager.getErrors(schema.form, entityCrud, dynamic);
        let errors = errorsObject.errors;

        const model = SalesOrdersModel.fromCrud(entityCrud);
        let errorProducts = Map();
        model.products.forEach((salesOrderProductModel, index) => {
            let errorProduct = Map();
            schema.products.forEach((schemaProduct) => {
                if (
                    schemaProduct.mandatory &&
                    isEmpty(
                        salesOrderProductModel.entity[schemaProduct.id.toLowerCase()],
                        schemaProduct.dataType,
                    )
                ) {
                    errorProduct = errorProduct.set(schemaProduct.id.toLowerCase(), '  ');
                }
            });
            if (errorProduct.size > 0) {
                errorProducts = errorProducts.set(index, errorProduct);
            }
        });
        if (errorProducts.size > 0) {
            errors = errors.set('products', errorProducts);
        }

        errorsObject.errors = errors;

        return errorsObject;
    }

    /*
    TO DEPRECATE
     */
    setReportsForm(reportSignedFormModel, success, error) {
        this.context.domainManager.setReportsForm(
            SALESORDERS.entity,
            reportSignedFormModel,
            (result) => {
                success(result);
            },
            error,
        );
    }

    duplicateCrud(entityCrud) {
        return entityCrud.update('products', (products) =>
            products.map((product) => product.getDuplicate()),
        );
    }

    exportExcel(filters, success, error) {
        const { list } = this.context.store.getState().orders;
        const sortField = list.get('orderField');
        const sortDir = list.get('orderDir');
        this.context.domainManager.exportExcel(filters, sortField, sortDir, (data) => {
            if (data.status === 1) {
                saveAs(
                    `${this.context.constants.getUrlDownloadDocument()}?token=${data.data}`,
                    'table.xls',
                );
                success(`${this.context.constants.getUrlDownloadDocument()}?token=${data.data}`);
            } else {
                error(data.errorMessage);
            }
        });
    }

    setAsPrincipal(id, principal, success) {
        this.context.domainManager.setAsPrincipal(
            id,
            principal,
            () => {
                success();
            },
            () => {},
        );
    }

    getFilterSchema = () => {
        const state = Context.store.getState();
        const currenUser = `${state.config.userData.nombre} ${state.config.userData.apellidos}`;

        return [
            {
                id: 'products',
                dataType: 'multipleValueList',
                description: getLiteral('label_product'),
                inputAttrs: {
                    ...FuzzyMap.productos,
                    isMulti: true,
                },
                extraFilterProps: {
                    id: 'productsQ',
                    dataType: 'multipleValueList',
                    description: getLiteral('label_product_quantity'),
                    isExtraFilter: true,
                    isMulti: true,
                },
                hint: getLiteral('action_searchproduct'),
                asExtra: false,
                beforeRenderValueList: this.beforeRenderValueList,
                overrideDefaultComponent: 'Products',
            },
            {
                id: 'productCategories',
                dataType: 'singleValueList',
                description: getLiteral('label_productcategory'),
                inputAttrs: {
                    list: 'productcategory',
                },
                hint: getLiteral('action_select'),
            },
            {
                id: 'productFamilies',
                dataType: 'singleValueList',
                description: getLiteral('label_product_family'),
                inputAttrs: {
                    ...FuzzyMap.productFamilies,
                },
                hint: getLiteral('action_select'),
            },
            {
                id: 'rates',
                dataType: 'singleValueList',
                description: getLiteral('label_rate'),
                fieldConfiguration: 'idTarifa',
                inputAttrs: {
                    list: 'tblTarifas',
                },
                hint: getLiteral('action_select'),
            },
            {
                id: 'states',
                dataType: 'singleValueList',
                description: getLiteral('label_status_salesorders'),
                fieldConfiguration: 'idEstadoSalesOrder',
                getExtraClassNameValue: this.getExtraClassNameStateValue,
                inputAttrs: {
                    list: 'tblEstadosSalesOrders',
                    filterOption: this.filterOptionStatesByType,
                },
                hint: getLiteral('action_select'),
            },
            {
                id: 'companies',
                dataType: 'singleValueList',
                description: getLiteral('label_account'),
                fieldConfiguration: 'idEmpresa',
                inputAttrs: {
                    ...FuzzyMap.empresas,
                },
                hint: getLiteral('action_searchcompany'),
            },
            {
                id: 'opportunities',
                dataType: 'singleValueList',
                description: getLiteral('title_opportunity'),
                fieldConfiguration: 'idExpediente',
                inputAttrs: {
                    ...FuzzyMap.expedientes,
                },
                hint: getLiteral('action_searchopportunity'),
            },
            {
                id: 'responsibles',
                dataType: 'singleValueList',
                description: getLiteral('label_owner'),
                fieldConfiguration: 'idComercial',
                inputAttrs: {
                    ...FuzzyMap.viewusuariosfullname,
                    defaultSearch: currenUser,
                },
                hint: getLiteral('action_searchowner'),
            },
            {
                id: 'environment',
                fieldConfiguration: 'idSucursal',
                dataType: 'singleValueList',
                description: getLiteral('label_environment'),
                inputAttrs: {
                    list: 'tblSucursales',
                },
                hint: getLiteral('action_searchowner'),
            },
            {
                id: 'amount',
                serverKeys: { from: 'amountMin', to: 'amountMax' },
                dataType: 'currency',
                description: getLiteral('label_total'),
                fieldConfiguration: 'totalAmount',
            },
            {
                id: 'date',
                serverKeys: { from: 'dateMin', to: 'dateMax' },
                dataType: 'date',
                description: getLiteral('label_valid_until'),
                fieldConfiguration: 'expectedCloseDate',
                locators: {
                    first: 'orders-filter__date-start',
                    last: 'orders-filter__date-end',
                },
            },
            {
                id: 'closeDate',
                serverKeys: { from: 'closeDateFrom', to: 'closeDateTo' },
                dataType: 'date',
                description: getLiteral('label_salesorders_close'),
                fieldConfiguration: 'CloseDate',
                locators: {
                    first: 'orders-filter__closing-date-start',
                    last: 'orders-filter__closing-date-end',
                },
            },
            {
                id: 'fcreado',
                description: getLiteral('label_created'),
                dataType: 'date',
                asExtra: true,
                isAudit: true,
                locators: {
                    first: 'orders-filter__creation-date-start',
                    last: 'orders-filter__creation-date-end',
                },
            },
            {
                id: 'followingItem',
                dataType: 'singleValueList',
                description: getLiteral('label_following'),
                generateOptions: () => {
                    return [
                        { label: getLiteral('label_following'), value: '1' },
                        { label: getLiteral('label_not_following'), value: '2' },
                    ];
                },
            },
            {
                id: 'salesOrderType',
                fieldConfiguration: 'salesOrderType',
                dataType: 'singleValueList',
                description: getLiteral('label_salesorder_type'),
                hint: getLiteral('label_selectone'),
                inputAttrs: {
                    list: 'tblTiposSalesOrders',
                },
                showOnlyAsCrossFilter: true,
                byParameter: state.config?.userData?.enableSalesOrdersTypes,
            },
            // Below are the filters related to the cross filters functionality of this entity
            // the one tagged as "isFakeCrossFilter" is to be shown as cross filter in the allowed entities
            // the ones tagged as "invisible" are standard filters of this entity that will be set from the
            // cross filters section of the matching entity (because UX & BE)
            {
                id: 'hasOrder',
                description: getLiteral('label_filters_cross_filters_has_salesorder'),
                dataType: 'bool',
                isFakeCrossFilter: true,
            },
            {
                id: 'hasAccount',
                dataType: 'bool',
                invisible: true,
            },
            {
                id: 'hasContact',
                dataType: 'bool',
                invisible: true,
            },
            {
                id: 'hasOpportunity',
                dataType: 'bool',
                invisible: true,
            },
        ];
    };

    filterOptionStatesByType(option) {
        const state = Context.store.getState();
        const filters = state?.entityFilters?.[SALESORDERS.entity]?.filters;
        const pipelineSelected = filters?.salesOrderType;

        const hasDependency =
            state.config?.standardFieldsSchemaMap?.SALESORDERS?.idEstadoSalesOrder
                ?.strParentField === 'salesOrderType';

        if (pipelineSelected && hasDependency) {
            const pipelineId = pipelineSelected.value[0]?.value;
            const idparent = option?.idparent || option?.data?.idparent;
            return idparent === pipelineId;
        }

        return true;
    }

    getSpecificFilterToPreFilter = (id) => {
        let schema = this.getFilterSchema();
        let field = schema.find((f) => {
            return f.id === id;
        });
        return field;
    };

    transformDataForEntityList = (backendData) => {
        let totalRows = backendData.TotalRows;
        if (backendData.State === '1' && totalRows === -1) {
            totalRows = 0;
        }
        return {
            Data: backendData.Result,
            MetaData: { totalRows: totalRows },
        };
    };

    afterOnChangeFilter = ({ refresh }) => {
        if (refresh) {
            this.context.store.dispatch(this.context.actions.OrdersActions.initGrid(true));
        }
    };

    getRateCaducityError = ({ value }) => {
        if (!value) return '';
        const state = Context.store.getState();
        const rates = state?.serverList?.ratesValueList?.data;
        const visibleRate = this.filterTblRates(rates)?.find(
            (rate) => Number(rate.id) === Number(value?.value || value),
        );

        return !visibleRate ? getLiteral('label_expired_rate') : '';
    };

    processDuplicatedData = (data) => {
        let newExtraFields = {};
        if (Array.isArray(data.extraFields)) {
            data.extraFields.forEach((tab) =>
                tab.data.forEach((field) => (newExtraFields[field.id] = field.value)),
            );
            data.extraFields = newExtraFields;
        }

        data.orderslines = (data.rawProducts || data.products).map((product) => ({
            description: product.description,
            discount1: product.discount1,
            discount2: product.discounts2,
            discount3: product.discount3,
            discount4: product.discount4,
            finalprice: product.finalprice,
            quantity: product.quantity,
            price: product.price,
            idproduct: product.idproduct,
            extraFields: Object.keys(product).reduce((obj, key) => {
                if (key.startsWith('z_')) obj[key] = product[key]?.value;
                return obj;
            }, {}),
        }));
        return data;
    };

    whileInitCrud = (data) => {
        const state = Context.store.getState();
        const enableSalesOrdersTypes = state.config.userData.enableSalesOrdersTypes;
        const salesOrderType = state.entityFilters[SALESORDERS.entity]?.filters?.salesOrderType;
        if (!enableSalesOrdersTypes || data.id || !salesOrderType) return;
        return Context.serverListManager
            .getList('tblTiposSalesOrders')
            .then((result) => {
                const currentType = salesOrderType?.value?.[0] || result?.[0];
                const defaultType = result?.find(
                    (type) =>
                        String(type.value) ===
                        String(
                            state.config?.standardFieldsSchemaMap?.SALESORDERS?.salesOrderType
                                ?.defaultValue,
                        ),
                );

                if (defaultType) data.salesOrderType = defaultType?.value;
                else if (currentType) data.salesOrderType = currentType?.value;
                return data;
            })
            .catch((error) => {
                console.error(error);
            });
    };

    formatFiltersToSend = (filters) => {
        const newFilters = translateFollowingItemForBackend(filters, SALESORDERS);
        return newFilters;
    };
}

function saveAs(uri, filename) {
    const link = document.createElement('a');
    if (typeof link.download === 'string') {
        document.body.appendChild(link); // Firefox requires the link to be in the body
        link.download = filename;
        link.href = uri;
        link.click();
        document.body.removeChild(link); // remove the link when done
    } else {
        location.replace(uri);
    }
}

const getGroup = (data) => {
    const result = {};
    if (data) {
        data.forEach((order) => {
            const group = result[order.idStateSalesOrder];
            if (!group) {
                result[order.idStateSalesOrder] = List();
            }
            result[order.idStateSalesOrder] = result[order.idStateSalesOrder].push(Map(order));
        });
    }

    return result;
};

const groupByState = (types, data) => {
    const result = getGroup(data);
    let resultMap = Map();
    let maxTotal = 0;
    types.forEach((type) => {
        const id = type['-id'];
        let list = result[id];
        list = list || List();
        const total = list.size > 0 ? list.get(0).get('rowCount') : 0;
        if (maxTotal < total) {
            maxTotal = total;
        }
        list = list.setSize(total);
        resultMap = resultMap.set(id, {
            id,
            intOrder: type.intorder,
            name: type.descripcion,
            win: type.win,
            lost: type.lost,
            data: list,
        });
    });
    return { data: resultMap, max: maxTotal };
};

const addOrderToState = (grid, offset, data) => {
    const result = getGroup(data);
    Object.keys(result).forEach((element) => {
        const orders = grid.get('data').get(element);
        result[element].forEach((order, index) => {
            orders.data = orders.data.splice(index + offset, 1, order);
        });
        grid = grid.setIn(['data', element], orders);
    });

    return { data: grid.get('data'), max: grid.get('total') };
};
