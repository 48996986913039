import { memo, useState, useCallback, useEffect, useMemo, useRef, useContext } from 'react';
import { SectionForm, Input } from 'hoi-poi-ui';
import SelectHoi from 'components/SelectHoi';
import { errorToast } from 'utils/toast';
import { getLiteral } from 'utils/getLiteral';
import dot from 'utils/dot';

import { getAutomationVariables } from 'services/AutomationsService';
import { AutomationBuilderCanvasContext } from '../AutomationBuilderCanvasContext';
import AutomationBuilderSidePanel from './AutomationBuilderSidePanel';

const AutomationBuilderActionSidePanel = memo(
    ({ data, node, nodePath, onChange, onClose, isTouched, setTouched }) => {
        const { getActions } = useContext(AutomationBuilderCanvasContext);
        const variables = useRef({});
        const localData = useRef();
        const [actions, setActions] = useState();
        const [entities, setEntities] = useState([]);
        const [fields, setFields] = useState([]);
        const [form, setForm] = useState(() => {
            const newNode = JSON.parse(JSON.stringify(node));
            return {
                ...newNode,
                name: newNode.parameters.entity ? newNode.name : undefined,
                parameters: {
                    ...newNode.parameters,
                    entity: newNode.parameters.entity
                        ? {
                              label: getLiteral(newNode.parameters.entity?.description),
                              value: newNode.parameters.entity?.value,
                              iconType: newNode.parameters.entity?.entity,
                              original: newNode.parameters.entity,
                          }
                        : undefined,
                },
                fields: newNode.parameters?.values?.map((value) => ({
                    ...value?.field,
                    label: getLiteral(value?.field?.literal),
                    value: value?.field?.key,
                    original: value?.field,
                })),
                values: newNode.parameters?.values?.reduce((obj, value) => {
                    obj[value.field?.key] = value?.value?.value;
                    return obj;
                }, {}),
            };
        });

        useEffect(() => {
            if (localData.current) return;
            localData.current = JSON.parse(JSON.stringify(data));
        }, [data]);

        useEffect(() => {
            // Getting actions and select lists when updating
            if (actions?.length > 0) return;

            getActions().then((result) => {
                setActions(result);
            });

            if (!form.parameters?.entity?.original?.entity) return;
            getAutomationVariables(data?.scene)
                .then((result) => {
                    setEntities(
                        result.entity.map((entity) => ({
                            label: getLiteral(entity.description),
                            value: entity?.value,
                            iconType: entity?.entity,
                            original: entity,
                        })),
                    );
                    variables.current = result;
                    let fields =
                        variables.current?.values?.fields[
                            form.parameters?.entity?.original?.entity
                        ];
                    setFields(
                        fields?.map((item) => {
                            return {
                                label: item.name,
                                options:
                                    item.fields?.map?.((field) => ({
                                        ...field,
                                        label: getLiteral(field.literal),
                                        value: field.key,
                                        original: field,
                                    })) || [],
                            };
                        }) || [],
                    );
                })
                .catch((e) => {
                    console.error(e);
                    errorToast({ text: getLiteral('error_generalerror') });
                });
        }, [actions?.length, data, form.parameters?.entity?.original?.entity, getActions]);

        const changeEvent = useCallback(
            (value) => {
                setTouched(true);
                setForm((current) => {
                    const newObj = {
                        ...current,
                        name: value,
                        parameters: {},
                        fields: [],
                        values: {},
                    };
                    localData.current = dot.set(localData.current, nodePath, newObj);
                    return newObj;
                });
                if (!value) return;

                getAutomationVariables(localData.current?.scene)
                    .then((result) => {
                        setEntities(
                            result.entity.map((entity) => ({
                                label: getLiteral(entity.description),
                                value: entity?.value,
                                iconType: entity?.entity,
                                original: entity,
                            })),
                        );
                        variables.current = result;
                    })
                    .catch((e) => {
                        console.error(e);
                        errorToast({ text: getLiteral('error_generalerror') });
                    });
            },
            [nodePath, setTouched],
        );

        const changeEntity = useCallback(
            (value) => {
                setTouched(true);
                setForm((current) => {
                    const newObj = {
                        ...current,
                        parameters: {
                            entity: value,
                        },
                        fields: [],
                        values: {},
                    };
                    localData.current = dot.set(localData.current, nodePath, newObj);
                    return newObj;
                });

                if (!value) return;

                let fields = variables.current.values.fields[value.original.entity];
                // Version 0.0 text only

                setFields(
                    fields
                        .filter((item) => item.fields?.find((field) => field.type === 'text'))
                        .map((item) => {
                            return {
                                label: item.name,
                                options:
                                    item.fields
                                        ?.filter((field) => field.type === 'text')
                                        .map?.((field) => ({
                                            ...field,
                                            label: getLiteral(field.literal),
                                            value: field.key,
                                            original: field,
                                        })) || [],
                            };
                        }),
                );
            },
            [nodePath, setTouched],
        );

        const changeFields = useCallback(
            (value) => {
                setTouched(true);
                setForm((current) => {
                    return {
                        ...current,
                        fields: value,
                    };
                });
            },
            [setTouched],
        );

        const changeValues = useCallback(
            (field) => (value) => {
                setTouched(true);
                setForm((current) => {
                    return {
                        ...current,
                        values: {
                            ...(current?.values || {}),
                            [field]: value,
                        },
                    };
                });
            },
            [setTouched],
        );

        const onDone = useCallback(() => {
            let values = [];
            if (form.fields?.length > 0) {
                values = form.fields.map((field) => ({
                    field: field.original,
                    value: {
                        type: 'field',
                        value: form.values[field.value],
                    },
                }));
            }

            onChange(nodePath, {
                name: form.name,
                parameters: { entity: form.parameters.entity.original, values },
                type: 'action',
            });
            setTouched(false);
        }, [setTouched, onChange, nodePath, form]);

        const getFieldByType = useCallback(
            (field) => {
                switch (field.type) {
                    case 'text':
                        return (
                            <Input
                                placeholder={getLiteral('placeholder_text_field')}
                                isFullWidth
                                onChange={changeValues(field.value)}
                                value={form?.values?.[field.value]}
                            />
                        );
                    default:
                        return null;
                }
            },
            [changeValues, form?.values],
        );

        const fieldsForm = useMemo(() => {
            // TODO Full types field form
            return form?.fields?.map((field) => (
                <SectionForm title={getLiteral(field.literal)} isExpandable>
                    <SelectHoi
                        value={{
                            label: getLiteral('label_automation_operator_equal_to'),
                            value: 'equalTo',
                        }}
                        isReadOnly
                        isFullWidth
                    />
                    {getFieldByType(field)}
                </SectionForm>
            ));
        }, [form?.fields, getFieldByType]);

        const isDoneDisabled = useMemo(() => {
            return !isTouched || !form?.fields?.length;
        }, [form, isTouched]);

        const showFields = form?.parameters?.entity && fields?.length > 0;

        return (
            <AutomationBuilderSidePanel
                title={getLiteral('label_action')}
                subtitle={getLiteral('label_action_desc')}
                onClose={onClose}
                isDoneDisabled={isDoneDisabled}
                onDone={onDone}
            >
                <SectionForm title={getLiteral('label_action_event')} isExpandable={false}>
                    <SelectHoi
                        placeholder={getLiteral('label_placeholder_event')}
                        options={actions}
                        onChange={changeEvent}
                        value={form.name}
                        useMenuPortal={false}
                        isFullWidth
                        usePlainValue
                    />
                </SectionForm>
                {form?.name && (
                    <SectionForm title={getLiteral('label_action_entity')} isExpandable={false}>
                        <SelectHoi
                            placeholder={getLiteral('label_placeholder_entity')}
                            options={entities}
                            onChange={changeEntity}
                            value={form.parameters?.entity}
                            useMenuPortal={false}
                            isReadOnly={!form.name}
                            isFullWidth
                        />
                    </SectionForm>
                )}
                {showFields && (
                    <SectionForm title={getLiteral('label_fields')} isExpandable={false}>
                        <SelectHoi
                            placeholder={getLiteral('label_placeholder_field')}
                            options={fields}
                            onChange={changeFields}
                            value={form.fields}
                            useMenuPortal={false}
                            selectAllLabel={getLiteral('label_selectall')}
                            isMulti
                            isFullWidth
                        />
                        {fieldsForm}
                    </SectionForm>
                )}
            </AutomationBuilderSidePanel>
        );
    },
);

export default AutomationBuilderActionSidePanel;
