import React, { memo, useContext, useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { Text, Button, Spacer, Popover, Link } from 'hoi-poi-ui';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';

import { ConfigActions } from 'actions';
import { getTabDashboards, putDashboards } from 'services/Dashboards';
import { CACHE_DASHBOARD_TAB_KEY } from 'constants/Constants';
import { METRICS } from 'constants/Entities';
import { errorToast } from 'utils/toast';
import { getLiteral, getLiteralWithParametersHtml } from 'utils/getLiteral';
import { logEvent } from 'utils/tracking';
import { ensureRoute } from 'utils/routes';

import GeneralTabs from 'components/GeneralTabs';
import { DashboardContext } from './DashboardContext';
import DashboardTabPopover from './DashboardTabPopover';
import AddDashboardPopover from './AddDashboardPopover';

const DashboardTabs = memo(() => {
    const timer = useRef();
    const dispatch = useDispatch();
    const cachedDashboardTabKey = useSelector(
        (state) => state.config?.components?.[CACHE_DASHBOARD_TAB_KEY] || '-1',
    );
    const [activeKey, setActiveKey] = useState(cachedDashboardTabKey);
    const { dashboards, setDashboards, setActiveDashboard } = useContext(DashboardContext);
    const dashboardsRef = useRef(dashboards);
    const [addDisabled, setAddDisabled] = useState(false);
    const permissions = useSelector((state) => ({
        sfmPermission: state.config?.permission?.sfm,
        dashboardPermission:
            state.config?.permission?.analyticsPro && state.config?.userData?.lookerAnalyticsViewer,
    }));

    useEffect(() => {
        if (dashboards.length !== dashboardsRef?.current?.length) {
            dashboardsRef.current = dashboards;
        }
    }, [dashboards]);

    useEffect(() => {
        setTimeout(() => setAddDisabled(dashboards?.length > 4));
    }, [dashboards?.length]);

    const updateDashboards = useCallback(
        (dashboards) => {
            setDashboards(dashboards);
            // We use dashboardsRef in addTab method to have an updated variable inside the method
            dashboardsRef.current = dashboards;
        },
        [setDashboards],
    );

    useEffect(() => {
        if (dashboards?.length) return;
        getTabDashboards()
            .then((result) => {
                if (!result?.length) return;
                updateDashboards(result);
                setActiveDashboard(
                    cachedDashboardTabKey
                        ? result.find((item) => String(item.id) === String(cachedDashboardTabKey))
                        : result[0],
                );
            })
            .catch((error) => {
                console.error(error);
                errorToast({ text: getLiteral('error_tryitagain') });
            });
    }, [cachedDashboardTabKey, dashboards, setActiveDashboard, updateDashboards]);

    const onChange = useCallback(
        (key) => {
            setActiveKey(key);
            setTimeout(() => {
                setActiveDashboard(dashboards?.find((item) => String(item.id) === String(key)));
                dispatch(ConfigActions.setConfigWeb(CACHE_DASHBOARD_TAB_KEY, key));
            });
            logEvent({
                event: 'dashboard',
                functionality: 'clickTab',
            });
        },
        [dashboards, dispatch, setActiveDashboard],
    );

    const onClose = useCallback(
        (props) => {
            const newDashboards = dashboards.filter(
                (item) => String(item.id) !== String(props.key),
            );
            updateDashboards(newDashboards);
            putDashboards(newDashboards).catch((e) => {
                console.error(e);
                errorToast({ text: getLiteral('error_tryitagain') });
            });
            if (String(activeKey) === String(props.key) && dashboards?.length > 1) {
                setTimeout(() => {
                    let currentIndex = dashboards.findIndex(
                        (item) => String(item.id) === String(activeKey),
                    );

                    let newActiveDashboard;
                    if (newDashboards[currentIndex])
                        newActiveDashboard = newDashboards[currentIndex];
                    else newActiveDashboard = newDashboards[currentIndex - 1];

                    setActiveKey(String(newActiveDashboard.id));
                    setActiveDashboard(newActiveDashboard);
                    dispatch(
                        ConfigActions.setConfigWeb(CACHE_DASHBOARD_TAB_KEY, newActiveDashboard.id),
                    );
                });
            }
        },
        [activeKey, dashboards, dispatch, setActiveDashboard, updateDashboards],
    );

    const onSorting = useCallback(
        (newTabs) => {
            clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                const newDashboards = newTabs.map((tab) => tab.dashboard);
                updateDashboards(newDashboards);
                putDashboards(newDashboards).catch((e) => {
                    console.error(e);
                    errorToast({ text: getLiteral('error_tryitagain') });
                });
            }, 350);
        },
        [updateDashboards],
    );

    const addTab = useCallback(
        (newDashboard) => {
            // We use dashboardsRef because when the method is executed from certain components
            // the callback do not recognize that dashboards has changed and the variable is not updated
            const newDashboards = [...dashboardsRef.current, newDashboard];
            updateDashboards(newDashboards);
            setActiveKey(String(newDashboard.id));
            setTimeout(() => {
                setActiveDashboard(newDashboard);
                dispatch(ConfigActions.setConfigWeb(CACHE_DASHBOARD_TAB_KEY, newDashboard.id));
                putDashboards(newDashboards).catch((e) => {
                    console.error(e);
                    errorToast({ text: getLiteral('error_tryitagain') });
                });
            });
        },
        [dispatch, setActiveDashboard, updateDashboards],
    );

    const tabs = useMemo(() => {
        const getIcon = (dashboard) => {
            switch (dashboard?.type?.value) {
                case 'Metabase':
                case 'ForceBI':
                    return 'chart';
                case 'SFM':
                    return 'salesForce';
                default:
                    return 'dashboard';
            }
        };
        const getColorClass = (dashboard) => {
            switch (dashboard?.type?.value) {
                case 'Metabase':
                case 'ForceBI':
                    return 'fm-dashboards-tabs__tab__orange';
                case 'SFM':
                    return 'fm-dashboards-tabs__tab__blue';
                default:
                    return 'fm-dashboards-tabs__tab__purple';
            }
        };
        return dashboards.map((dashboard) => ({
            ...dashboard,
            dashboard,
            key: dashboard.id,
            tabClassName: classnames('fm-dashboards-tabs__tab', getColorClass(dashboard)),
            icon: getIcon(dashboard),
            title: dashboard?.title,
            fixed: dashboard?.id === -1,
            popoverContent: <DashboardTabPopover dashboard={dashboard} />,
            popoverWidth: 250,
        }));
    }, [dashboards]);

    const buttonToMetrics = useMemo(() => {
        if (!permissions.sfmPermission || !permissions.dashboardPermission) return null;
        return (
            <div className="fm-dashboards-tabs__button-popover__redirect">
                <Spacer y={2} />
                {getLiteralWithParametersHtml(
                    'label_can_view_all_dashboards_from',
                    [getLiteral('label_metrics_and_reports')],
                    (item) => (
                        <Link onClick={() => ensureRoute(METRICS.route)}>{item}</Link>
                    ),
                )}
            </div>
        );
    }, [permissions]);

    const DisabledButtonPopover = useMemo(() => {
        if (!permissions.sfmPermission && !permissions.dashboardPermission) return null;
        const button = (
            <Button
                className="fm-dashboards-tabs__add-btn"
                type="primary-soft"
                size="small"
                icon="addPlus"
                isDisabled={addDisabled}
            >
                {getLiteral('action_add_tab')}
            </Button>
        );
        if (addDisabled) {
            return (
                <Popover
                    trigger={['hover']}
                    placement="bottom"
                    content={
                        <div className="fm-dashboards-tabs__button-popover">
                            <Text type="subtitle">{getLiteral('label_limit_tab_reached')}</Text>
                            <Spacer y={2} />
                            <Text>{getLiteral('label_limit_tab_reached_desc')}</Text>
                            {buttonToMetrics}
                        </div>
                    }
                >
                    {button}
                </Popover>
            );
        } else {
            return (
                <AddDashboardPopover placement="bottomLeft" onAdd={addTab}>
                    {button}
                </AddDashboardPopover>
            );
        }
    }, [
        addDisabled,
        addTab,
        permissions.dashboardPermission,
        permissions.sfmPermission,
        buttonToMetrics,
    ]);

    if (!dashboards?.length) return;

    return (
        <div className="fm-dashboards-tabs">
            <GeneralTabs
                tabs={tabs}
                activeKey={String(activeKey)}
                onClose={onClose}
                onChange={onChange}
                onSorting={onSorting}
            />
            {DisabledButtonPopover}
        </div>
    );
});

export default DashboardTabs;
