import { memo, useContext, useMemo, useState, useEffect } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Text, Divider, Spacer } from 'hoi-poi-ui';
import { getLiteral } from 'utils/getLiteral';
import { findNodeByType, AUTOMATION_ENTITIES } from '../utils';
import { AutomationBuilderCanvasContext } from '../AutomationBuilderCanvasContext';
import AutomationBuilderBox from '../components/AutomationBuilderBox';

const AutomationBuilderActionBox = memo((props) => {
    const { data, getActions } = useContext(AutomationBuilderCanvasContext);
    const [actions, setActions] = useState([]);
    const nodeData = useMemo(() => findNodeByType(data?.scene, props.id), [data?.scene, props.id]);

    useEffect(() => {
        getActions()
            .then((result) => {
                setActions(result);
            })
            .catch((e) => {
                console.error(e);
                errorToast({ text: getLiteral('error_generalerror') });
            });
    }, [getActions]);

    const content = useMemo(() => {
        if (!actions?.length) return;
        const entity = AUTOMATION_ENTITIES.find(
            (entity) => entity.trueName === nodeData?.parameters?.entity?.entity,
        );

        if (!entity) return;

        const action = actions?.find((a) => a.value === nodeData?.name);
        // TODO Only text field now
        return (
            <>
                <Text type="subtitle" bold>
                    {`${action.label} ${getLiteral(entity?.labels?.singular)?.toLowerCase()}`}
                </Text>
                <Spacer y={2} />
                <Divider />
                <Spacer y={2} />
                {nodeData?.parameters?.values
                    .filter((value) => value.field.type === 'text')
                    .map((value) => (
                        <div className="fm-automation-builder__action-box__field">
                            <Text type="subtitle" bold>
                                {getLiteral(value.field.literal)}
                            </Text>
                            <Spacer y={2} />
                            <div className="fm-automation-builder__action-box__field-row">
                                <Text color="neutral700">
                                    {getLiteral('label_automation_operator_equal_to')}:
                                </Text>
                                <Text color="neutral900" isTruncated>
                                    {value.value?.value || '-'}
                                </Text>
                            </div>
                            <Spacer y={2} />
                        </div>
                    ))}
            </>
        );
    }, [actions, nodeData]);

    return (
        <>
            <Handle type="target" position={Position.Top} id="top" isConnectable={false} />
            <AutomationBuilderBox
                {...props}
                className="fm-automation-builder__action-box"
                title={getLiteral('label_action')}
                icon={
                    nodeData?.parameters?.entity?.entity
                        ? nodeData?.parameters?.entity?.entity
                        : 'automations'
                }
                emptyPlaceholder={getLiteral('action_automation_add_action')}
            >
                {content}
            </AutomationBuilderBox>
        </>
    );
});

export default AutomationBuilderActionBox;
