import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ACTIVITIES, CONVERSATIONS } from 'constants/Entities';
import Context from 'managers/Context';
import {
    ACTIVITY_TYPE_ANNOTATION,
    ACTIVITY_TYPE_CALL,
    ACTIVITY_TYPE_CHECKIN,
    ACTIVITY_TYPE_EMAIL,
    ACTIVITY_TYPE_OPPORTUNITY_CHECKIN,
    ACTIVITY_TYPE_FAST_CHECKIN,
    ACTIVITY_TYPE_VIDEO_CALL,
    ACTIVITY_TYPE_VIDEO_CHECKIN,
    ACTIVITY_TYPE_MAILCHIMP,
    ACTIVITY_TYPE_ZENDESK,
    ACTIVITY_TYPE_WHATSAPP,
} from 'models/ActivityModel';
import { ActionsAndMenu } from 'containers/components/EntityDetailV2';
import ActionsOptions from '../components/ActionsOptions';
import MenuOptions from '../components/MenuOptions';
import TitleContentDetail from '../components/components/TitleContentDetail';
import { ensureRoute } from 'utils/routes';
import { getLiteral } from 'utils/getLiteral';
import { getBackendBoolean } from 'utils/fm';

const useActivitiesDetail = () => {
    const { dispatch, getState } = Context.store;
    const { EntityDetailActions } = Context.actions;
    const state = getState();
    const location = useLocation();

    const afterCloseTab = useCallback(() => {
        const { pathname } = location;
        const { active } = state.entityList;
        if (
            (active !== ACTIVITIES.entity && active !== CONVERSATIONS.entity) ||
            !pathname.includes(ACTIVITIES.entity)
        )
            return;

        const basePath = {
            [ACTIVITIES.entity]: ACTIVITIES.route,
            [CONVERSATIONS.entity]: `${CONVERSATIONS.route}${ACTIVITIES.route}`,
        }[active];

        const params = pathname.replace(`${basePath}`, '').split('/').filter(Boolean);
        const activeKey = params.length ? `${ACTIVITIES.entity}-${params[0]}` : null;

        const payload = activeKey
            ? { activeKey, tabs: { [activeKey]: { primary: true } } }
            : { activeKey, tabs: {} };

        ensureRoute(pathname);
        dispatch(EntityDetailActions.setActiveTab(payload));
    }, [EntityDetailActions, dispatch, location, state.entityList]);

    const getHeaderItems = useCallback(({ activity, isFromWidgetDetail }) => {
        let itemsList, leftContent;

        switch (parseInt(activity.ActivityType, 10)) {
            case ACTIVITY_TYPE_ANNOTATION:
            case ACTIVITY_TYPE_CHECKIN:
            case ACTIVITY_TYPE_OPPORTUNITY_CHECKIN:
            case ACTIVITY_TYPE_FAST_CHECKIN:
                itemsList = ['addEvent', 'addTask', 'share', 'uploadFile'];
                break;
            case ACTIVITY_TYPE_CALL:
                let isReceived = false;
                let isMissed = false;

                if (activity.IsReceived === '0' || activity.IsReceived === 0) isReceived = true;
                else if (activity.IsReceived === '1' || activity.IsReceived === 1)
                    isReceived = false;
                else isMissed = true;

                let labelCall = isReceived
                    ? getLiteral('label_callreceived')
                    : getLiteral('label_callmade');

                if (isMissed) {
                    labelCall = getLiteral('label_missed_call');
                }

                leftContent = <TitleContentDetail label={labelCall} />;

                itemsList = ['addEvent', 'addTask', 'share'];
                break;
            case ACTIVITY_TYPE_EMAIL:
                const labelEmail = getBackendBoolean(activity.IsSent)
                    ? getLiteral('label_emailsent')
                    : getLiteral('label_emailreceived');

                leftContent = <TitleContentDetail label={labelEmail} />;

                itemsList = ['addEvent', 'addTask', 'uploadFile'];
                break;
            case ACTIVITY_TYPE_VIDEO_CALL:
            case ACTIVITY_TYPE_VIDEO_CHECKIN:
            case ACTIVITY_TYPE_MAILCHIMP:
            case ACTIVITY_TYPE_ZENDESK:
            case ACTIVITY_TYPE_WHATSAPP:
                itemsList = ['addEvent', 'addTask', 'uploadFile'];
                break;
            default:
                break;
        }

        if (!itemsList) return {};

        return {
            leftContent,
            actionsAndMenu: (
                <ActionsAndMenu
                    actionsOptions={
                        <ActionsOptions
                            activity={activity}
                            isFromWidgetDetail={isFromWidgetDetail}
                        />
                    }
                    menuOptions={
                        <MenuOptions
                            activity={activity}
                            isFromWidgetDetail={isFromWidgetDetail}
                            itemsList={itemsList}
                        />
                    }
                />
            ),
        };
    }, []);

    return {
        afterCloseTab,
        getHeaderItems,
    };
};

export default useActivitiesDetail;
