import { useCallback, useMemo } from 'react';
import {
    ACTIVITIES,
    AGENDA,
    COMPANIES,
    CONTACTS,
    CONVERSATIONS,
    OPPORTUNITIES,
    SALESORDERS,
    TASKS,
    PRODUCTS,
    DASHBOARD,
} from 'constants/Entities';

import Context from 'managers/Context';
import { getEntityFromString } from 'utils/getEntityFromString';
import { getCurrentPath } from 'utils/routes';

export const ENTITIES_WITH_TABS_SUPPORT = [
    ACTIVITIES.entity,
    AGENDA.entity,
    CONVERSATIONS.entity,
    COMPANIES.entity,
    OPPORTUNITIES.entity,
    SALESORDERS.entity,
    TASKS.entity,
    CONTACTS.entity,
    PRODUCTS.entity,
    DASHBOARD.entity,
];

export const getHasTabsEnabled = (state) => {
    state = state || Context.store.getState();
    const currentEntityPath = getCurrentPath().split('/')?.filter(Boolean)[0];
    const active = getEntityFromString(`/${currentEntityPath}`)?.entity || null;
    return ENTITIES_WITH_TABS_SUPPORT.includes(active) || !active;
};

const useEntityDetail = () => {
    const { dispatch, getState } = Context.store;
    const { EntityCrudActions, EntityDetailActions } = Context.actions;
    const state = getState();

    const tabs = useMemo(() => {
        return Object.keys(state.entityDetail.tabs);
    }, [state]);

    const hasTabsEnabled = useMemo(() => {
        return getHasTabsEnabled(state);
    }, [state]);

    const hasTabs = useMemo(() => {
        return hasTabsEnabled && tabs?.length > 0;
    }, [hasTabsEnabled, tabs?.length]);

    const openCrud = useCallback(() => {
        const { entityDetail } = state;
        const { active } = entityDetail;
        const { entity, id, data, avoidRedirects } = entityDetail[active];
        const extraInfo = entity === ACTIVITIES.entity ? { activityType: data.ActivityType } : {};
        dispatch(
            EntityCrudActions.init({
                entity: getEntityFromString(entity),
                id,
                isFromTab: true,
                extraInfo,
                avoidRedirects,
            }),
        );
    }, [EntityCrudActions, dispatch, state]);

    const openTab = useCallback(
        ({
            entity,
            id,
            hasCrudInDetail = false,
            extra,
            forceRefresh = false,
            avoidOpenDrawer = false,
            info = null,
            toTab = true,
            avoidRedirects = false,
        }) => {
            const activeKey = `${entity.entity}-${id}`;

            const state = getState();
            const tabs = Object.keys(state.entityDetail.tabs);
            if (tabs.includes(activeKey)) {
                dispatch(EntityDetailActions.setActiveTab({ activeKey }));
            } else {
                dispatch(EntityCrudActions.setActiveCrud(activeKey));
                dispatch(
                    EntityDetailActions.open(
                        entity,
                        id,
                        hasCrudInDetail,
                        extra,
                        forceRefresh,
                        avoidOpenDrawer,
                        info,
                        toTab,
                        avoidRedirects,
                    ),
                );
            }
        },
        [EntityCrudActions, EntityDetailActions, dispatch, getState],
    );

    const primaryDetail = useMemo(() => {
        const tabs = state.entityDetail.tabs;
        for (const key in tabs) {
            if (tabs.hasOwnProperty(key) && tabs[key].primary) {
                return key;
            }
        }
        return null;
    }, [state]);

    return {
        hasTabs,
        hasTabsEnabled,
        getHasTabsEnabled,
        openCrud,
        openTab,
        primaryDetail,
    };
};

export const withEntityDetail = (Component) => (props) => {
    const hookProps = useEntityDetail();
    return <Component {...props} {...hookProps} />;
};

export default useEntityDetail;
