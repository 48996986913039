import { axios } from './axios';
import { handleErrors } from './utils';

export function getAutomations() {
    return new Promise((resolve, reject) => {
        axios
            .get(`automations/v1`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function getAutomation(id) {
    return new Promise((resolve, reject) => {
        axios
            .get(`automations/v1/${id}`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function enableAutomation(id, enabled) {
    return new Promise((resolve, reject) => {
        axios
            .put(`automations/v1/${id}/enabled`, { enabled })
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function putAutomation(id, data) {
    return new Promise((resolve, reject) => {
        axios
            .put(`automations/v1/${id}`, data)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function createAutomation(data) {
    return new Promise((resolve, reject) => {
        axios
            .post(`automations/v1`, data)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function deleteAutomation(id) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`automations/v1/${id}`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function getAutomationVariables(body) {
    return new Promise((resolve, reject) => {
        axios
            .post(`automations/v1/variables`, body)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}

export function getAutomationActions() {
    return new Promise((resolve, reject) => {
        axios
            .get(`automations/v1/actions`)
            .then(({ data }) => resolve(data))
            .catch(handleErrors(reject));
    });
}
