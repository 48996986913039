import { memo, useMemo } from 'react';
import { Text } from 'hoi-poi-ui';
import useLoginLiterals from 'utils/literals/useLoginLiterals';

import './styles.scss';

const ForceBIMobile = memo(() => {
    const { loading, getLiteral } = useLoginLiterals();

    const illustrationForceBI = useMemo(
        () => (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 168 144" fill="none">
                <g clipPath="url(#a)">
                    <path
                        fill="#FDF6CB"
                        d="M29.886 123.405a27.508 27.508 0 0 1-14.967-10.051 27.902 27.902 0 0 1-5.614-17.228c.023-1.548.185-3.129.83-4.534.646-1.405 1.845-2.62 3.344-2.953 1.637-.364 3.364.38 4.584 1.538 1.22 1.159 2.02 2.688 2.756 4.206 4.432 9.146 7.8 18.959 9.06 29.059l.007-.037Z"
                    />
                    <path
                        fill="#FFDE99"
                        d="m12.737 88.944 3.106 7.117 3.106 7.116c.984 2.254 1.966 4.509 2.983 6.748 1.008 2.22 2.05 4.424 3.162 6.592a90.478 90.478 0 0 0 3.572 6.363c.16.258.32.514.483.77.113.177.394.012.281-.166a86.37 86.37 0 0 1-3.637-6.325 141.454 141.454 0 0 1-3.202-6.571 391.397 391.397 0 0 1-2.986-6.716l-3.095-7.091-3.106-7.117-.387-.886c-.084-.192-.364-.026-.28.166Z"
                    />
                    <path
                        fill="#FFF1CC"
                        d="M28.59 123.454a15.98 15.98 0 0 1-10.462-1.143 16.17 16.17 0 0 1-7.527-7.416c-.402-.811-.741-1.675-.782-2.581-.041-.905.256-1.862.945-2.443.753-.634 1.848-.719 2.792-.449.944.269 1.768.846 2.557 1.434 4.75 3.541 9.125 7.719 12.483 12.62l-.006-.022Z"
                    />
                    <path
                        fill="#FFC766"
                        d="m10.46 110.231 3.519 2.85 3.518 2.849c1.114.902 2.228 1.806 3.355 2.691a88.767 88.767 0 0 0 3.407 2.562 52.313 52.313 0 0 0 4.015 2.599c.105.061.208-.101.101-.162a50.04 50.04 0 0 1-3.582-2.294 81.63 81.63 0 0 1-3.421-2.54 222.158 222.158 0 0 1-3.349-2.674l-3.505-2.839L11 110.424l-.438-.355c-.095-.077-.196.086-.101.162Z"
                    />
                    <path fill="#D4D8DC" d="M159.301 24.78h-108v.892h108v-.891Z" />
                    <path
                        fill="#273C50"
                        d="M54.981 22.649c.717 0 1.299-.593 1.299-1.325 0-.731-.582-1.324-1.299-1.324s-1.298.593-1.298 1.324c0 .732.581 1.325 1.298 1.325ZM58.715 22.649c.717 0 1.298-.593 1.298-1.325 0-.731-.581-1.324-1.298-1.324-.718 0-1.299.593-1.299 1.324 0 .732.582 1.325 1.299 1.325ZM62.448 22.649c.718 0 1.299-.593 1.299-1.325 0-.731-.581-1.324-1.299-1.324-.717 0-1.298.593-1.298 1.324 0 .732.581 1.325 1.298 1.325Z"
                    />
                    <path
                        fill="#D4D8DC"
                        d="M77.677 34.457h-18.18a1.5 1.5 0 0 1-1.071-.453 1.56 1.56 0 0 1-.444-1.092c0-.41.16-.803.444-1.093a1.5 1.5 0 0 1 1.07-.452h18.181a1.5 1.5 0 0 1 1.072.452c.284.29.443.683.443 1.093 0 .41-.16.802-.443 1.092a1.5 1.5 0 0 1-1.072.453Z"
                    />
                    <path
                        fill="#E9ECEE"
                        d="M102.567 41.96h-43.07a1.5 1.5 0 0 1-1.071-.452 1.56 1.56 0 0 1-.444-1.092c0-.41.16-.803.444-1.093a1.5 1.5 0 0 1 1.07-.452h43.071c.402 0 .787.163 1.071.452.284.29.444.683.444 1.093 0 .41-.16.803-.444 1.092-.284.29-.669.453-1.071.453Z"
                    />
                    <path
                        fill="#FF8C00"
                        d="M116.467 44.946a20.35 20.35 0 0 0-3.42 10.192 20.419 20.419 0 0 0 2.236 10.529 19.932 19.932 0 0 0 7.249 7.836 19.411 19.411 0 0 0 10.177 2.89V58.858l-16.242-13.913Z"
                    />
                    <path
                        fill="#FFC766"
                        d="M132.709 36.222a19.373 19.373 0 0 0-9.18 2.312 19.808 19.808 0 0 0-7.062 6.412l16.242 13.913V36.222Z"
                    />
                    <path
                        fill="#7D8A96"
                        d="M132.708 76.613h-.216V36.002h.216c5.281 0 10.346 2.139 14.08 5.947 3.734 3.808 5.832 8.973 5.832 14.358 0 5.386-2.098 10.55-5.832 14.358-3.734 3.809-8.799 5.948-14.08 5.948Zm.217-40.169V76.17c5.128-.058 10.027-2.176 13.633-5.895 3.607-3.719 5.629-8.738 5.629-13.968s-2.022-10.249-5.629-13.968c-3.606-3.718-8.505-5.837-13.633-5.895Z"
                    />
                    <path
                        fill="#7D8A96"
                        d="M132.703 76.614h.216V36.003h-.216c-5.281 0-10.346 2.139-14.08 5.947-3.734 3.808-5.832 8.973-5.832 14.358 0 5.386 2.098 10.55 5.832 14.358 3.734 3.809 8.799 5.948 14.08 5.948Zm-.216-40.169v39.726c-5.129-.058-10.028-2.176-13.634-5.895-3.606-3.719-5.629-8.738-5.629-13.968s2.023-10.249 5.629-13.968c3.606-3.718 8.505-5.837 13.634-5.895Z"
                    />
                    <path
                        fill="#CBF3FC"
                        d="M72.05 84.89h-3.679a.965.965 0 0 1-.688-.291 1.004 1.004 0 0 1-.286-.703V73.083c0-.264.103-.516.286-.702a.965.965 0 0 1 .688-.292h3.68c.258 0 .505.105.688.292.182.186.285.438.285.702v10.815c0 .263-.103.515-.285.701a.966.966 0 0 1-.689.292Z"
                    />
                    <path
                        fill="#FF8C00"
                        d="M82.872 84.89h-3.68a.966.966 0 0 1-.688-.291 1.004 1.004 0 0 1-.285-.703v-15.89c0-.264.103-.517.285-.703a.966.966 0 0 1 .689-.291h3.679c.258 0 .506.105.688.291.183.186.286.439.286.702v15.892c0 .263-.103.515-.286.701a.966.966 0 0 1-.688.292Z"
                    />
                    <path
                        fill="#98D5F1"
                        d="M93.694 84.89h-3.68a.966.966 0 0 1-.688-.292 1.004 1.004 0 0 1-.286-.701V63.59c0-.264.103-.516.286-.702a.965.965 0 0 1 .688-.291h3.68c.258 0 .505.105.688.29.183.187.285.44.285.703v20.306c0 .263-.103.515-.285.701a.966.966 0 0 1-.688.292Z"
                    />
                    <path
                        fill="#7D8A96"
                        d="M72.05 85h-3.679c-.287 0-.562-.117-.765-.324a1.116 1.116 0 0 1-.317-.78V59.618c0-.293.114-.573.317-.78.203-.207.478-.323.765-.324h3.68c.287 0 .562.117.764.324.203.207.317.487.318.78v24.278c0 .293-.115.573-.318.78a1.073 1.073 0 0 1-.764.324Zm-3.679-26.044a.644.644 0 0 0-.459.194.67.67 0 0 0-.19.468v24.278a.67.67 0 0 0 .19.468.643.643 0 0 0 .46.194h3.679c.172 0 .337-.07.459-.194a.67.67 0 0 0 .19-.468V59.618a.67.67 0 0 0-.19-.468.644.644 0 0 0-.46-.194h-3.679ZM82.872 85h-3.68c-.286 0-.561-.117-.764-.324a1.116 1.116 0 0 1-.317-.78V59.618c0-.293.114-.573.317-.78.203-.207.478-.323.765-.324h3.68c.286 0 .561.117.764.324.203.207.317.487.317.78v24.278c0 .293-.114.573-.317.78a1.073 1.073 0 0 1-.765.324Zm-3.68-26.044a.644.644 0 0 0-.458.194.67.67 0 0 0-.19.468v24.278a.67.67 0 0 0 .19.468.644.644 0 0 0 .459.194h3.68c.171 0 .336-.07.458-.194a.67.67 0 0 0 .19-.468V59.618a.67.67 0 0 0-.19-.468.643.643 0 0 0-.459-.194h-3.68ZM93.693 85h-3.68c-.286 0-.561-.117-.764-.324a1.116 1.116 0 0 1-.317-.78V59.618c0-.293.114-.573.317-.78.203-.207.478-.323.765-.324h3.68c.286 0 .561.117.764.324.203.207.317.487.317.78v24.278c0 .293-.114.573-.317.78a1.073 1.073 0 0 1-.765.324Zm-3.68-26.044a.644.644 0 0 0-.458.194.67.67 0 0 0-.19.468v24.278a.67.67 0 0 0 .19.468.643.643 0 0 0 .459.194h3.68c.171 0 .337-.07.458-.194a.67.67 0 0 0 .19-.468V59.618a.67.67 0 0 0-.19-.468.644.644 0 0 0-.459-.194h-3.68Z"
                    />
                    <path
                        fill="#FFC5AC"
                        d="M31.121 90.255a1.984 1.984 0 0 0-.278-1.641 1.96 1.96 0 0 0-1.427-.839l-1.669-6.788-2.442 2.672 1.96 6.036c-.013.485.152.956.462 1.326a1.95 1.95 0 0 0 2.58.374c.4-.266.69-.672.814-1.14Z"
                    />
                    <path
                        fill="#5695C7"
                        d="M26.646 88.167a.873.873 0 0 1-.488-.522L23.372 79a9.146 9.146 0 0 1 .148-6.387l2.686-6.723a2.852 2.852 0 0 1 1.139-1.844 2.802 2.802 0 0 1 2.099-.491 2.799 2.799 0 0 1 1.828 1.15 2.849 2.849 0 0 1 .484 2.118L28.05 77.695l1.297 8.853a.89.89 0 0 1-.492.881l-1.497.72a.868.868 0 0 1-.712.018Z"
                    />
                    <path
                        fill="#FFC5AC"
                        d="m40.42 121.457 2.379-.001 1.132-9.254-3.512.001v9.254Z"
                    />
                    <path
                        fill="#273C50"
                        d="M47.387 123.684H39.91v-2.913h4.588c.766 0 1.501.307 2.043.853.542.546.846 1.287.846 2.06Z"
                    />
                    <path
                        fill="#FFC5AC"
                        d="m28.581 121.457 2.38-.001 1.131-9.254-3.511.001v9.254Z"
                    />
                    <path
                        fill="#273C50"
                        d="M35.549 123.684H28.07v-2.913h4.589c.766 0 1.5.307 2.043.853.541.546.846 1.287.846 2.06Z"
                    />
                    <path
                        fill="#37526C"
                        d="m41.07 83.74 2.091 16.71c.288 2.297.489 4.602.603 6.917l.564 11.363a.792.792 0 0 1-.213.579.777.777 0 0 1-.562.243h-3.175a.773.773 0 0 1-.506-.189.785.785 0 0 1-.261-.476l-2.855-18.966a.392.392 0 0 0-.377-.332.386.386 0 0 0-.388.32l-3.44 18.574a.772.772 0 0 1-.704.623l-2.92.187a.773.773 0 0 1-.776-.485.788.788 0 0 1-.057-.32l.943-30.443L41.07 83.74Z"
                    />
                    <path
                        fill="#FFC5AC"
                        d="M31.803 59.94c2.633 0 4.767-2.152 4.767-4.807 0-2.654-2.134-4.806-4.767-4.806-2.632 0-4.766 2.152-4.766 4.806 0 2.655 2.134 4.807 4.766 4.807Z"
                    />
                    <path
                        fill="#76BDE8"
                        d="M28.067 88.437c1.164 2.74 6.21 4.696 7.375.782.9-3.025 4.467-4.297 6.041-4.718a.78.78 0 0 0 .471-.36.791.791 0 0 0 .084-.59l-4.4-17.427a6.135 6.135 0 0 0-1.482-2.707 6.061 6.061 0 0 0-2.605-1.63c-3.497-1.092-7.184 1.157-7.776 4.798a27.068 27.068 0 0 0-.309 3.085c-.365 7.688 1.834 16.962 2.6 18.767Z"
                    />
                    <path
                        fill="#FFC5AC"
                        d="M43.862 88.432A1.98 1.98 0 0 0 43.018 87a1.943 1.943 0 0 0-1.63-.266l-3.978-5.728-1.326 3.378 3.98 4.923c.161.456.483.836.904 1.07a1.948 1.948 0 0 0 2.54-.587c.28-.394.406-.878.354-1.36Z"
                    />
                    <path
                        fill="#5695C7"
                        d="M38.943 88.105a.867.867 0 0 1-.642-.31l-5.682-7.058a9.121 9.121 0 0 1-2.138-6.013l.11-7.247a2.86 2.86 0 0 1 .405-2.133 2.825 2.825 0 0 1 1.784-1.218 2.79 2.79 0 0 1 2.115.41 2.824 2.824 0 0 1 1.207 1.8l.418 11.488 4.366 7.791a.89.89 0 0 1-.146 1.001L39.6 87.83a.873.873 0 0 1-.657.275Z"
                    />
                    <path
                        fill="#273C50"
                        d="M28.185 59.065a.772.772 0 0 0 .976-.152.785.785 0 0 0 .17-.312l.002-.01c.087-.366.015-.747-.01-1.12-.025-.375.012-.786.264-1.061.472-.513 1.31-.241 1.991-.371.4-.09.752-.323.993-.657.24-.333.353-.743.315-1.154a2.613 2.613 0 0 0-.042-.233 1.316 1.316 0 0 1 .282-1.128 1.296 1.296 0 0 1 1.063-.454c.688.04 1.431.25 2.001-.264a1.491 1.491 0 0 0 .355-1.605c-.326-.947-1.245-1.442-2.155-1.715a7.82 7.82 0 0 0-6.682 1.106c-.574.408-1.111.921-1.356 1.586-.245.664-.127 1.503.425 1.941a3.617 3.617 0 0 0-.824 3.485c.273.886 1.575 1.736 2.232 2.117Z"
                    />
                    <path
                        fill="#A9B1B9"
                        d="M62.886 124h-51.43a.196.196 0 0 1-.137-.057.198.198 0 0 1 0-.277.192.192 0 0 1 .138-.057h51.429a.196.196 0 0 1 0 .391Z"
                    />
                </g>
                <defs>
                    <clipPath id="a">
                        <path fill="#fff" d="M9.301 20h150v104h-150z" />
                    </clipPath>
                </defs>
            </svg>
        ),
        [],
    );

    if (loading) return null;

    return (
        <div className="forcebi-mobile">
            <div className="forcebi-mobile__container">
                <div className="forcebi-mobile__image">{illustrationForceBI}</div>
                <Text type="subtitle1">{getLiteral('label_forcebi_only_visible_web_title')}</Text>
                <Text type="caption" color="neutral700">
                    {getLiteral('label_forcebi_only_visible_web_description')}
                </Text>
            </div>
        </div>
    );
});

export default ForceBIMobile;
