import { memo, useMemo } from 'react';
import { ReactFlow, ReactFlowProvider, Background, BackgroundVariant, Panel } from '@xyflow/react';
import { useTheme } from 'hoi-poi-ui';

import { AutomationBuilderCanvasProvider } from './AutomationBuilderCanvasContext';
import AutomationBuilderTriggerBox from './types/AutomationBuilderTriggerBox';
import AutomationBuilderConditionsBox from './types/AutomationBuilderConditionsBox';
import AutomationBuilderActionBox from './types/AutomationBuilderActionBox';
import AutomationBuilderCanvasPanel from './AutomationBuilderCanvasPanel';
import AutomationBuilderToolbar from './AutomationBuilderToolbar';
import AutomationBuilderCanvasSidePanel from './AutomationBuilderCanvasSidePanel';

import NodeLayouter from './components/NodesLayouter';

import '@xyflow/react/dist/style.css';
import './styles.scss';

const nodeTypes = {
    trigger: AutomationBuilderTriggerBox,
    conditions: AutomationBuilderConditionsBox,
    action: AutomationBuilderActionBox,
};

const initialNodes = [
    {
        id: 'entity_crud',
        position: { x: 0, y: 0 },
        data: {},
        type: 'trigger',
    },
    {
        id: 'conditions_logic',
        position: { x: 0, y: 100 },
        data: {},
        type: 'conditions',
    },
    {
        id: 'update_entity_related',
        position: { x: 0, y: 200 },
        data: {},
        type: 'action',
    },
];

const initialEdges = [
    {
        id: 'e-trigger-condition',
        source: 'entity_crud',
        target: 'conditions_logic',
    },
    {
        id: 'e-condition-action',
        source: 'conditions_logic',
        target: 'update_entity_related',
    },
];

const AutomationBuilderCanvas = memo(({ data, onChange }) => {
    const theme = useTheme();
    const proOptions = useMemo(() => ({ hideAttribution: true }), []);
    const defaultEdgeOptions = useMemo(() => ({ className: 'fm-automation-builder__edge' }), []);

    return (
        <AutomationBuilderCanvasProvider defaultNode={data.scene} data={data}>
            <div className="fm-automation-builder__canvas">
                <div className="fm-automation-builder__canvas-inner">
                    <AutomationBuilderToolbar
                        enabled={data?.enabled || false}
                        onChange={onChange}
                    />

                    <ReactFlowProvider>
                        <ReactFlow
                            defaultNodes={initialNodes}
                            defaultEdges={initialEdges}
                            proOptions={proOptions}
                            defaultEdgeOptions={defaultEdgeOptions}
                            nodeTypes={nodeTypes}
                            nodesDraggable={false}
                            nodesConnectable={false}
                            nodesFocusable={false}
                            edgesFocusable={false}
                            elementsSelectable={true}
                            maxZoom={3}
                            panOnScroll={true}
                            panOnScrollSpeed={1}
                            fitView
                        >
                            <Panel position="bottom-right">
                                <AutomationBuilderCanvasPanel
                                    enabled={data?.enabled || false}
                                    onChange={onChange}
                                />
                            </Panel>
                            <Background
                                color={theme.colors.neutral300}
                                size={3}
                                variant={BackgroundVariant.Dots}
                            />
                        </ReactFlow>
                        <NodeLayouter />
                    </ReactFlowProvider>
                </div>
                <AutomationBuilderCanvasSidePanel onChange={onChange} data={data} />
            </div>
        </AutomationBuilderCanvasProvider>
    );
});

export default AutomationBuilderCanvas;
