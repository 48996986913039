import React, { memo, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UsersActions, EntityFiltersActions } from 'actions';
import { Select, useTheme } from 'hoi-poi-ui';
import { KPIS } from 'constants/Entities';
import { sort } from 'utils/arrays';
import { getLiteral } from 'utils/getLiteral';
import { addAlpha } from 'utils/color';
import UserAvatar from 'containers/components/avatars/UserAvatar';
import SearchRow from 'components/SearchRow';

import './styles.scss';

const mapStateToProps = (state) => {
    return {
        usersTreeData: state.users.usersTree.data,
        selectedUser: state.users.usersTree.selectedUser,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSelectedUser: bindActionCreators(UsersActions, dispatch).setSelectedUser,
        refreshSelectedUsers: bindActionCreators(UsersActions, dispatch).refreshSelectedUsers,
        changeFilter: bindActionCreators(EntityFiltersActions, dispatch).changeFilter,
    };
};

const SearchKpis = memo(
    ({ usersTreeData, setSelectedUser, refreshSelectedUsers, changeFilter, selectedUser }) => {
        const theme = useTheme();
        const onSelectFromFilter = useCallback(
            (selected) => {
                const newSelectedUser = usersTreeData[selected.value];

                setSelectedUser(newSelectedUser);
                refreshSelectedUsers(newSelectedUser);
                changeFilter({
                    entity: KPIS,
                    filter: {
                        id: 'userId',
                        hideForCount: true,
                    },
                    value: newSelectedUser.id,
                    refresh: true,
                });
            },
            [usersTreeData, setSelectedUser, refreshSelectedUsers, changeFilter],
        );

        const options = useMemo(() => {
            let newOptions =
                (usersTreeData &&
                    Object.keys(usersTreeData).length > 0 &&
                    Object.keys(usersTreeData).map((current) => {
                        const option = {
                            value: usersTreeData[current].id,
                            label: usersTreeData[current].name,
                        };
                        return option;
                    })) ||
                [];

            newOptions = sort(newOptions, 'label');
            return newOptions;
        }, [usersTreeData]);

        const customOption = useCallback(
            (item) => {
                if (!item.label || !item.value) return;
                return (
                    <SearchRow
                        className="fm-row-search__kpis"
                        onClick={() => onSelectFromFilter(item)}
                        avatar={<UserAvatar id={parseInt(item.value, 10)} size="large" />}
                        title={item.label}
                        entity={KPIS}
                    />
                );
            },
            [onSelectFromFilter],
        );

        return (
            <Select
                className="fm-search-kpis"
                options={options}
                placeholder={getLiteral('cfm_placeholder_search_user')}
                customOption={customOption}
                hideDropdownIndicator={true}
                isClearable={false}
                size="small"
                overrides={{
                    formControl: { style: { width: '275px' } },
                    inputComponents: { style: { width: '275px' } },
                    controlFocused: {
                        style: {
                            border: `1px solid ${addAlpha(theme.colors.blue200, 0.5)}`,
                            backgroundColor: addAlpha(theme.colors.blue100, 0.5),
                        },
                    },
                }}
                onChange={(item) => onSelectFromFilter(item)}
                shouldSetValueOnChange={false}
            />
        );
    },
);

export default connect(mapStateToProps, mapDispatchToProps)(SearchKpis);
